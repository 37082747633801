import React from 'react';
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import { Link } from 'react-router-dom';
import cookie from 'react-cookies';
import $ from "jquery";

class PrivacyPolicy extends React.Component {
	componentDidMount() {
	  }
   render() {
      return (
         <div>
            <Header/>
            	{/*--  cart-center --*/}
			    <div className="privacy-content">
				    <div className="container">
					      	<h2> OUR PRIVACY POLICY </h2>
						    <div className="row">
							  	<div className="col-md-12">
							    	<p>By using the Site, you indicate your acceptance of this Privacy Policy. As used herein, “the Site”” refers to the service and network located at www.wishuponmystory.com and its subdomains, mobile version(s), downloadable apps, and any associated applications that are owned and/or operated by Norih Studio, Inc., and its affiliates (collectively, “us,” “we,” “our,” or “the Company”). If you do not agree to this Privacy Policy, you are not permitted to access the Site and you should not do so.</p>
									<p>You can contact us at: </p>
									<p>Norih Studio, Inc.</p>
									<p>ATTN: Wish upon my story Privacy Matters</p>
									<p>contactus@wishuponmystory.com</p>
									<h3>PROTECTION OF PERSONAL DATA</h3>
									<p>Norih Studio, Inc. is committed to protecting your personal data, and as such Norih Studio, Inc. has implemented all of the necessary requirements to conform to its obligations to protect your personal data. Here we want to show you how we use your personal data. We only use that information where we have a proper legal basis for doing so.</p>
									<p>This privacy policy will give you the information you need to help you understand what information we collect, how we use it, and what choices you have about your personal data.</p>
									<p>The data that you input into our Website can only be viewed by the department concerned, which has all of the necessary data confidentiality agreements in place.</p>
									<h3>HOW WE COLLECT YOUR PERSONAL DATA</h3>
									<h5>Voluntary Information OR where permission is granted to obtain it</h5>
									<p>We collect personal information when users visit our site as follows:</p>
									<p>Voluntary information:</p>
									<p>Purchase of Products from Wishuponmystory.com</p>
									<p>If you purchase a product from the Site, information may be collected from you, including your credit card and contact information. We do not retain credit card information.
	When you place an order on the Wish upon my story website, we need to know your name and shipping address. Your telephone number and email address will be collected and used to contact you as it pertains to your order and only when necessary. We collect your billing address and credit card / payment information to process your order.</p>
									<p>When you personalize a product on the Wish upon my story website, we need to know the personalization details such as name, from, dedication and possibly a photo. Depending on the product we may ask for additional personalization details including but not limited to birth date, gender and family member names.</p>
									<h5>EMAIL</h5>
									<p>We collect the e-mail addresses of those who communicate with us via e-mail and information volunteered by the consumers, such as survey information or site registrations. When you sign up for our website, you are voluntarily giving us certain information. This may include your name, email, address or phone number, and any other information you provide us. If you choose to buy something on our website, you provide us with payment information, contact information (i.e. address and phone number) and what was purchased. Should you purchase something for someone else on our website, you are also providing us with their contact information. We use email in the following ways:</p>
									<ol className="li-style">
										<li>To keep our users informed about new releases. We may send updates, newsletters, marketing materials, promotions and other information that may be of interest to you. </li>
										<li>To update you about your order status and shipping information</li>
										<li>To remind you of items in your online basket or products abandoned. </li>
										<li>To capture feedback through surveys regarding your experience on the site</li>

									</ol>
									<h3>POST MAIL AND TELEPHONE NUMBER</h3>

									<p>Persons who supply us with their telephone numbers online may only receive telephone contact from us with information regarding orders they have placed online.</p>
									<p>If you decide to voluntarily provide us with your personal information, the data will be saved in our secure databases where appropriate procedures to protect your personal information are in place.</p>
									<p>In accordance with the Law, you have the right to access, modify and correct data about yourself. Should you wish to do so, please contact Norih Studio, Inc. by emailing us at contactus@wishuponmystory.com.
	Site optimization and Technical information: For each visitor to our website, our Web server automatically recognizes only the consumer's domain name or anonymized internet protocol address, not the e-mail address. We collect only the domain name or anonymized internet protocol address, not the e-mail address of visitors to our website. Our servers automatically record information including information that your browser sends whenever you visit a website or a mobile app. This may use your domain name, your anonymized internet protocol address, the address of the web pages visited, browser type and settings, date and time of the views, and other aggregate information.</p>
									<p>We may also collect information about the device you are using, including the type of device, the operating system, device settings and crash data. Whether we collect some or all of this information depends on the type of device and its settings. To learn about the policies associated with your device, please check the policies of your device manufacturer or software provider.</p>
									<p>The information we collect is used to improve the content of our website. It is not shared with other organizations for marketing or commercial purposes.</p>
									<h3>Affiliates, advertisers, partners and other third party services:</h3>
									<p>The information we collect is used for the following:</p>
									<ol className="li-style">
										<li>To provide our products to you and help make them better.</li>
										<li>To show you ads you might be interested in. For example, if you purchased a book from us, we may suggest other books we think you might like. </li>
										<li>If you make a purchase on our site, we save your order, address and payment information to process and ship your order and so you can use them the next time you want to order something on our site.</li>
										<li>To respond to questions or comments you may have.</li>

									</ol>
									<h3>Cookies:</h3>
									<p>A cookie is a text file that may be registered by your browser software, subject to your acceptance, in a dedicated space on the hard drive of your device (e.g. computer, tablet) when you consult an online service with your browser.</p>
									<p>We use cookies to record session information, such as items that consumers add to their shopping carts. This information helps us to better understand the areas of interest for our visitors, with the aim of making improvements for our users. We use data in the following ways:</p>
									<ol className="li-style">
										<li>To provide you with information about our products and offerings or deliver relevant advertising which may interest you</li>
										<li>To contact you regarding information you may have requested</li>
										<li>To contact you regarding your order status</li>
										<li>To notify you about changes to our website, products or services</li>
										<li>To administer the website and operations. This may include data analysis, research, A/B testing, or troubleshooting purposes</li>
										<li>To understand our visitors through the use of heatmaps, screen recordings, polls and surveys</li>
										<li>To understand the effectiveness of advertising we serve to you and others</li>
										<li>To ensure our website is secure</li>

									</ol>
									<p>By continuing to browse the Website, you are agreeing to our use of cookies. You may refuse to accept cookies or choose to be informed before accepting cookies. To do so, we recommend that you read the Help Section of your internet browser to learn the steps that you should take with cookies. If you adjust your browser settings to block all cookies (some of which may be essential) you may not be able to access some or all of our site.</p>
									<h3>WHAT WE DO WITH YOUR PERSONAL DATA</h3>
									<p>WHAT WE DO WITH YOUR PERSONAL DATA</p>

									<p>We keep your information for only as long as we need it to fulfill the purposes as described in this policy. If your information is no longer needed, and it is not required that we maintain it for our legal or regulatory obligations, we will remove it or depersonalize it so that you cannot be identified.</p>

									<p>We use the information we collect to provide a better service to you. This information is important to the nature of the services we provide. It allows us to make our services relevant, interesting and personal to your own interests. We use the information to improve our site and our products, improve Norih Studio, Inc., send you updates and activities or interests, emails regarding updates or notifications.</p>

									<p>From time to time, we may use customer information for new, unanticipated uses not previously disclosed in our privacy notice. If our information practices change at some time in the future, we will post the policy changes to our website to notify you of these changes and provide you with the ability to opt out of these new uses. If you are concerned about how your information is used, you should check back at our website periodically.</p>

									<p>Customers may prevent their information from being used for purposes other than those for which it was originally collected by sending us an e-mail using the contactus@wishuponmystory.com&nbsp;above or writing us at the above address.</p>

									<p>Upon request, we provide site visitors with access to contact information (e.g., name, address, phone number) that we maintain about them. Consumers can access this information by sending an e-mail at contactus@wishuponmystory.com or writing to us at the above address. We also offer visitors the ability to have inaccuracies corrected in contact information. Consumers can have this information corrected by sending us e-mail at the above e-mail address or writing to us at the above address.</p>

									<p>Customers can also object to us processing their information. You may contact us and tell us to stop using your information, including any marketing emails, notifications or mailings.</p>

									<p>If you are a European Union user, you may also have your information deleted. If you would like to have the information deleted, please contact us. If you&#39;re based in the EEA and think that we haven&#39;t complied with data protection laws, you have a right to lodge a complaint with the Data Protection Commission or with your local supervisory authority.</p>

									<h3>HOW AND WHEN WE SHARE YOUR INFORMATION</h3>

									<p>Third party companies or individuals that we employ may have access to and process information on our behalf based on our instructions and in compliance with this Privacy Policy.<br />
									<br />
									We send the purchased product order to a printer for fulfillment and shipping along with your shipping information including first name, last name, address, city, country, state/province, postal code and telephone number.</p>

									<p>We send the personalized product to a printer to be printed and shipped directly to your shipping address. We share with the printer your personalized product and shipping information including first name, last name, address, city, country, state/province, postal code and telephone number.</p>

									<p>We share payment card information with the payment companies we use to store your payment information. Payments will be processed by a third party processor, Stripe and is not stored on Wish upon my story servers. We will collect specific Personal Information required for Stripe to process payments. This includes credit card numbers, billing addresses, security (CVV) codes and any other relevant financial information. By providing payment information, you agree to the processing of that information and the transfer of that information to Stripe to process the payment. All such information will be transferred directly to Stripe and not retained, stored or used by Wish upon my story without prior consent. All such transferred information is subject to the privacy policy of Stripe rather than this policy. For more information regarding the security of your personal information related to credit card processing, visit&nbsp;<a href="https://www.braintreepayments.com/features/data-security"><strong>HTTPS://WWW.BRAINTREEPAYMENTS.COM/FEATURES/DATA-SECURITY</strong></a>. Be sure to review all privacy policies published by our third-party providers and contact them directly if you have questions as they may have privacy terms that differ from our Privacy Policy. We have implemented appropriate safeguards to help prevent unauthorized access, maintain data security, and make proper use of the information we collect. However, no transmission of data over the Internet is guaranteed to be completely secure. Thus, while we strive to protect your personal information, we cannot absolutely guarantee the security of any information you provide to us.</p>

									<p>Our wholly owned subsidiaries and affiliates may also have access to and process your information. If we were to engage in a merger, acquisition, bankruptcy, dissolution, reorganization, or similar transaction or proceeding that involves the transfer of the information described in this Policy, we would share your information with a party involved in such a process (for example, a potential seller).</p>

									<h3>OUR SECURITY</h3>

									<p>We always use industry-standard encryption technologies when transferring and receiving consumer data exchanged with our site.</p>

									<p>If you feel that this site is not following its stated information policy, you may contact us at the above addresses or phone number, state or local chapters of the Better Business Bureau, state or local consumer protection office, or with the Data Protection Commission or with your local supervisory authority.</p>

									<p>It is your responsibility to review this Privacy Policy from time to time to be aware of any such change. Your continued use of any of Norih Studio, Inc.&#39;s services, including www.wishuponmystory.com, will indicate your agreement to any such change.</p>

									<h3>SAFE SHOPPING GUARANTEE</h3>

									<p>www.wishuponmystory.com guarantees your satisfaction, security, and privacy. We use the latest encryption technology to keep your personal information safe. Our site is hosted with state-of-the-art, built-in security and performance to ensure an enjoyable and safe shopping experience.</p>

									<p>Shopping at www.wishuponmystory.com is absolutely safe. You never have to worry about credit card safety when shopping at our site.</p>

									<p>Our secure server software (SSL) is the industry standard and among the best software available today for secure commerce transactions. It encrypts all of your personal information, including credit card number, name, and address, so that it cannot be read as the information travels over the Internet.</p>

									<p>We guarantee that each purchase is protected and safe. If fraudulent charges are ever made, you will not have to pay for them.</p>

									<p>In the unlikely event that you are subject to fraudulent charges, remember to first notify your credit card provider in accordance with its reporting rules and procedures. If, for any reason, you are held responsible for this amount, Norih Studio, Inc., will cover the entire liability for you, up to $50, as long as the unauthorized use of your credit card resulted through no fault of your own from purchases made from Norih Studio, Inc., while using our secure server.</p>

									<h3>POLICY ON CHILDREN’S INFORMATION</h3>

									<p>The Children&#39;s Online Privacy Protection Act took effect on April 21, 2000, and imposes certain requirements on websites directed toward children under 13 that collect information on those children, or on websites that knowingly collect information on children under the age of 13. www.wishuponmystory.com&#39;s website is not directed toward children under 13, and it is our site&#39;s policy not to collect personal information on any person under 13. If you are based in the EEA, you may only use Norih Studio, Inc. if you are over the age at which you can provide consent to data processing under the laws of your country or if verifiable parental consent for your use of www.wishuponmystory.com site has been provided to us.</p>

									<p>No information should be submitted to or posted on the Site by any Minor (a user younger than 13 years of age). If a minor between the ages of 13 and 18 accesses or registers for the Site, or signs up to receive our newsletter, they should do so with parental or guardian consent. Unless otherwise disclosed during collection, we do not knowingly provide any personally identifying information about Minors, regardless of its source, to third parties, nor is this information knowingly used by us for any marketing or promotional purposes.</p>

									<p>By registering for this site, or registering to receive our newsletter, you are certifying that you are at least 13 years old.</p>

									<p><em>Last updated and effective as of: 14/02/2020</em></p>
									
								</div>
							</div>
					</div>
				</div>
				{/*--  cart-center  end--*/}
            <Footer/>
         </div>
      );
   }
}

export default PrivacyPolicy;