import React from 'react';
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import cookie from 'react-cookies';
import { Link } from 'react-router-dom';
import $ from "jquery";

class TermsAndConditions extends React.Component {
	componentDidMount() {
	  }
   render() {
      return (
         <div>
            <Header/>
            	{/*--  cart-center --*/}
			    <div className="add-book-cart">
				    <div className="container">
				      	<h2 className="text-center"> 	TERMS AND CONDITIONS OF<br/>
								WWW.WISHUPONMYSTORY.COM 
						</h2>
					    <div className="row">
						  	<div className="col-md-12">
						       	<h3 >PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THIS WEBSITE.</h3>
						       	<p>The following Terms and Conditions govern and apply to your use of or reliance upon this website maintained by Norih Studio, Inc. (the “Website”).</p>
								<p>Your access or use of the Website indicates that you have read, understand and agree to be bound by these Terms and Conditions and any other applicable laws, statutes and/or regulations. We may change these Terms and Conditions at any time without notice, effective upon its posting to the Website. Your continued use of the Website will be considered your acceptance to the revised Terms and Conditions.</p>
								<p><strong>1. AGE RESTRICTION</strong></p>

								<p>You must be at least thirteen (13) years of age to use this Website or any services contained herein. Your access or use of this Website indicates your representation that you are at least thirteen (13) years of age. We assume no responsibility or liability for any misrepresentation of your age.</p>

								<p><br />
								<strong>2. INTELLECTUAL PROPERTY</strong></p>

								<p>All intellectual property on the Website is owned by us or our licensors, which includes materials protected by copyright, trademark, or patent laws. All trademarks, service marks and trade names are owned, registered and/or licensed by us. All content on the Website, including but not limited to text, software, code, designs, graphics, photos, sounds, music, videos, applications, interactive features and all other content is a collective work under Canadian and other copyright laws and is the proprietary property of the Company; All rights reserved.</p>

								<p><br />
								<strong>3. USE OF COMPANY MATERIALS</strong></p>

								<p>We may provide you with certain information as a result of your use of the Website including, but not limited to, documentation, data, or information developed by us, and other materials which may assist in the use of the Website or Services (&quot;Company Materials&quot;). The Company Materials may not be used for any other purpose than the use of this Website and the services offered on the Website. Nothing in these Terms of Use may be interpreted as granting any license of intellectual property rights to you.</p>

								<p><br />
								<strong>4. PAYMENT</strong></p>

								<p>When you make a purchase on the Website, you agree to provide a valid instrument to make a payment. Pay attention to the details of the transaction, as your total price may include taxes, fees, and shipping costs, all of which you are responsible for.</p>

								<p>When you provide a payment instrument to us, you confirm that you are permitted to use that payment instrument. When you make a payment, you authorize us (and our designated payment processor) to charge the full amount to the payment instrument you designate for the transaction. You also authorize us to collect and store that funding instrument, along with other related transaction information.</p>

								<p>If you pay by credit or debit card, we may obtain a pre-approval from the issuer of the card for an amount as high as the full price. If you cancel a transaction before completion, this pre-approval may result in those funds not otherwise being immediately available to you.</p>

								<p>We may cancel any transaction if we believe the transaction violates these Terms, or if we believe doing so may prevent financial loss.</p>

								<p>In order to prevent financial loss to you or to us, we may contact your funding instrument issuer, law enforcement, or affected third parties (including other users) and share details of any payments you are associated with, if we believe doing so may prevent financial loss or a violation of law.</p>

								<p><br />
								<strong>5. SALE OF GOODS AND SERVICES</strong></p>

								<p>We may sell goods or services or allow third parties to sell goods or services on the Website. We undertake to be as accurate as possible with all information regarding the goods and services, including product descriptions and images. However, we do not guarantee the accuracy or reliability of any product information and you acknowledge and agree that you purchase such products at your own risk.</p>

								<p><br />
								<strong>6. SHIPPING, DELIVERY AND RETURN POLICY</strong></p>

								<p>You agree to ensure payment for any items you may purchase from us and you acknowledge and affirm that prices are subject to change. When purchasing a physical good, you agree to provide us with a valid email and shipping address, as well as valid billing information. We reserve the right to reject or cancel an order for any reason, including errors or omissions in the information you provide to us. If we do so after payment has been processed, We will issue a refund to you in the amount of the purchase price. We also may request additional information from you prior to confirming a sale and we reserve the right to place any additional restrictions on the sale of any of our products. For the sale of physical products, we may preauthorize your credit or debit card at the time you place the order or we may simply charge your card upon shipment. You agree to monitor your method of payment. Shipment costs and dates are subject to change from the costs and dates you are quoted due to unforeseen circumstances.<br />
								<br />
								For any questions, concerns, or disputes, you agree to contact us in a timely manner at the following:</p>

								<p>Norih Studio, Inc.<br />
								Mississauga, Ontario<br />
								contactus@wishuponmystory.com</p>

								<p>If you are unhappy with anything you have purchased on our Website, you may do the following:</p>

								<p>Wish upon my story sells customized books that are printed on demand and one of a kind. There are not refund the goods and all sales are final. If there is a defect or information in the interior content is incorrect, please contact us at contactus@wishuponmystory.com so that we can resend you a correct book.</p>

								<p><br />
								<strong>7. ACCEPTABLE USE</strong></p>

								<p>You agree not to use the Website for any unlawful purpose or any purpose prohibited under this clause. You agree not to use the Website in any way that could damage the Website, the services or the general business of Norih Studio, Inc.<br />
								<br />
								You further agree not to use and/or access the Website:</p>

								<p>a) To harass, abuse, or threaten others or otherwise violate any person&#39;s legal rights;</p>

								<p>b. To violate any intellectual property rights of us or any third party;</p>

								<p>c. To upload or otherwise disseminate any computer viruses or other software that may damage the property of another;</p>

								<p>d. To perpetrate any fraud;</p>

								<p>e. To engage in or create any unlawful gambling, sweepstakes, or pyramid scheme;</p>

								<p>f. To publish or distribute any obscene or defamatory material;</p>

								<p>g. To publish or distribute any material that incites violence, hate or discrimination towards any group;</p>

								<p>h. To unlawfully gather information about others.</p>

								<p><br />
								<strong>8. PROTECTION OF PRIVACY</strong></p>

								<p>Through your use of the Website, you may provide us with certain information. By using the Website, you authorize us to use your information in Canada and any other country where we may operate.</p>

								<p>We may receive information from external applications you use to access our Website, or we may receive information through various web technologies, such as cookies, log files, clear gifs, web beacons or others.</p>

								<p>We use the information gathered from you to ensure your continued good experience on our website. We may also track certain of the passive information received to improve our marketing and analytics, and for this, we may work with third-party providers, including other marketers.</p>

								<p>If you would like to disable our access to any passive information we receive from the use of various technologies, you may choose to disable cookies in your web browser.</p>

								<p><br />
								<strong>9. REVERSE ENGINEERING &amp; SECURITY</strong></p>

								<p>You may not undertake any of the following actions:</p>

								<p>a. Reverse engineer, or attempt to reverse engineer or disassemble any code or software from or on the Website;</p>

								<p>b. Violate the security of the Website through any unauthorized access, circumvention of encryption or other security tools, data mining or interference to any host, user or network.</p>

								<p><br />
								<strong>10. INDEMNIFICATION</strong></p>

								<p>You defend and indemnify Norih Studio, Inc. and any of its affiliates and hold us harmless against any and all legal claims and demands, including reasonable attorney&#39;s fees, which may arise from or relate to your use or misuse of the Website, your breach of these Terms and Conditions, or your conduct or actions. We will select our own legal counsel and may participate in our own defence, if we wish to so.</p>

								<p><br />
								<strong>11. SPAM POLICY</strong></p>

								<p>You are strictly prohibited from using the Website or any of our services for illegal spam activities, including gathering email addresses and personal information from others or sending any mass commercial emails.</p>

								<p><br />
								<strong>12. THIRD-PARTY LINKS &amp; CONTENT</strong></p>

								<p>We may occasionally post links to third party websites or other services. We are not responsible or liable for any loss or damage caused as a result of your use of any third party services linked to from our Website.</p>

								<p><br />
								<strong>13. VARIATION</strong></p>

								<p>To the extent any part or sub-part of these Terms and Conditions is held ineffective or invalid by any court of law, the prior, effective version of these Terms and Conditions be considered enforceable and valid to the fullest extent.</p>

								<p><br />
								<strong>14. SERVICE INTERRUPTIONS</strong></p>

								<p>We may need to interrupt your access to the Website to perform maintenance or emergency services on a scheduled or unscheduled basis. You agree that your access to the Website may be affected by unanticipated or unscheduled downtime, for any reason, but that the we will have no liability for any damage or loss caused as a result of such downtime.</p>

								<p><br />
								<strong>15. NO WARRANTIES</strong></p>

								<p>Your use of the Website is at your sole and exclusive risk and any services provided by us are on an &quot;as is&quot; basis. We disclaim any and all express or implied warranties of any kind, including, but not limited to the implied warranty of fitness for a particular purpose and the implied warranty of merchantability. We make no warranties that the Website will meet your needs or that the Website will be uninterrupted, error-free, or secure. We also make no warranties as to the reliability or accuracy of any information on the Website or obtained through the Services. Any damage that may occur to you, through your computer system, or as a result of loss of your data from your use of the Website is your sole responsibility and we are not liable for any such damage or loss.</p>

								<p><br />
								<strong>16. PRIVACY</strong></p>

								<p>In addition to these Terms and Conditions, this Website has a Privacy Policy that describes how your personal data is collected and how cookies are used on the Website. For more information, you may refer to our Privacy Policy, available on the Website.</p>

								<p>By using or browsing this Website, you also acknowledge that you have read our Privacy Policy.</p>

								<p><br />
								<strong>17. LIMITATION ON LIABILITY</strong></p>

								<p>We are not liable for any damages that may occur to you as a result of your use of the Website, to the fullest extent permitted by law. The maximum liability of Norih Studio, Inc. arising from your use of the Website is limited to the greater of one hundred ($100) Canadian Dollars or the amount you paid to Norih Studio, Inc. in the last six (6) months. This applies to any and all claims by you, including, but not limited to, lost profits or revenues, consequential or punitive damages, negligence and strict liability.</p>

								<p><a name="_GoBack"></a></p>

							</div>
						</div>
					</div>
				</div>
				{/*--  cart-center  end--*/}
            <Footer/>
         </div>
      );
   }
}

export default TermsAndConditions;