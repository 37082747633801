import React from 'react';
import Header from './layouts/Header';
import { Link } from 'react-router-dom';
import Footer from './layouts/Footer';
import $ from "jquery";
import { connect } from 'react-redux';
import {Action} from '../_action/action';
import { history } from '../_helpers/history';
import {urlConstants} from '../_constant/url_constant';
import DangerButton from './imageCropper';
import axios from 'axios';
import cookie from 'react-cookies';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Button ,Popover, OverlayTrigger} from 'react-bootstrap';

class detailsEidBook extends React.Component {
	constructor(props) {
       super(props);
       this.state = {
       	child_type: '',
       	child_img:'',
       	Glasses: '0',
       	AttachPhotoEnable: false,
       	color: '',
       	name_meaning: '',
       	child_avtar_type: '',
       	bookDiscription: '',
       	reviewsList: '',
       	openModal: false,
       	image: '',
       	isuploading: false,
       	imageName: '',
       	selectedLanguage :localStorage.getItem('lang')?localStorage.getItem('lang'):'english' 
       }
       this.handleSubmit = this.handleSubmit.bind(this);
       this.handleChange = this.handleChange.bind(this);
       this.avtarChange = this.avtarChange.bind(this);
       this.onKeyPressed = this.onKeyPressed.bind(this);
       this.languageChange = this.languageChange.bind(this);
   }

   	componentDidMount() {
   		this.props.parentsPost();
	   	window.$(document).ready(function() {
	   		window.$('html, body').animate({
		    scrollTop: window.$("#scroll").offset().top
		}, 1000);
	     	window.$("#show_hide").click(function () {
	     	window.$("#toggle_tst").show()
		});
		window.$("#show_hide1").click(function () {
		    window.$("#toggle_tst1").show()
		});
		window.$("#show_hide2").click(function () {
		    window.$("#toggle_tst2").show()
		});
		window.$("#show_hide3").click(function () {
		    window.$("#toggle_tst3").show()
			});
		});
		document.documentElement.setAttribute("lang", "en");
		document.documentElement.removeAttribute("class");
		$('.mydiv .container').click(()=>{
			setTimeout(()=>{
				$('.mydiv').css({'display':'none'});
			},1500)
		})
	}
	closeCropper= ()=>{
		this.setState({openModal:false});
	}
	languageChange(e){
		//ert(e.target);
		//alert(document.getElementById("languagedropdown").value);
		var selectedVal = document.getElementById("languagedropdown").value;
		localStorage.setItem('lang',selectedVal)
		this.setState({selectedLanguage: selectedVal});
	}
   	handleChange (e){
   		//ert(e.target.name);
   		if(e.target.name == 'child_name'){
	   		if(e.target.value.length > 18){
	   		}else{
	   			this.setState({[e.target.name]: e.target.value});
	   		}
	   	}else{
	   		
	   		if(e.target.name == 'attach_photo'){
	   			console.log(e.target.name);
	   			if(e.target.value === "1"){
	   				console.log('yes');
	   				this.setState({AttachPhotoEnable: true});
	   			}else{
	   				console.log('no');
	   				this.setState({AttachPhotoEnable: false});
	   			}

	   		}else{
	   			this.setState({[e.target.name]: e.target.value});
	   		}
	   		
	   		
	   		
	   	}
	   	
   	}
   	onKeyPressed (e){
   		let text = e.target.value;
   		let lengthText = 310 - text.length;
   		$("#word_remaining_text").html(lengthText);
   		//document.getElementById("word_remaining_text").innerHTML = lengthText;
   	}
   	handleFileChange=(e)=>{
   		let file = e.target.files[0];
   		console.log('file',file)
		
  		let the = this;
   		if(file){
	   		let reader = new FileReader();
	   		reader.onloadend = () => {
		  			the.setState({
		          		openModal: true,
		       			image: reader.result,
	          		})
	        }
	        reader.readAsDataURL(file);
	    }
	     $('#add_image').val(''); 
   	}
   	avtarChange(){

   	}
   	 dataURItoBlob = (dataURI)=> {
    // convert base64/URLEncoded data component to raw binary data held in a string
	    var byteString;
	    if (dataURI.split(',')[0].indexOf('base64') >= 0)
	        byteString = atob(dataURI.split(',')[1]);
	    else
	        byteString = unescape(dataURI.split(',')[1]);

	    // separate out the mime component
	    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

	    // write the bytes of the string to a typed array
	    var ia = new Uint8Array(byteString.length);
	    for (var i = 0; i < byteString.length; i++) {
	        ia[i] = byteString.charCodeAt(i);
	    }

	    return new Blob([ia], {type:mimeString});
	}
	myFunction1=()=> {
		this.setState({child_type : 'boy'})
	  var x = document.getElementById("mYDIV");
	  var e = document.getElementById("myDIV");
	  e.style.display = "none";
	  if (x.style.display === "block") {
	    x.style.display = "none";
	  } else {
	    x.style.display = "block";
	  }
	}

	myFunction=()=> {
		this.setState({child_type : 'girl'})
	  var x = document.getElementById("myDIV");
	  var e = document.getElementById("mYDIV");
	  e.style.display = "none";
	  if (x.style.display === "block") {
	    x.style.display = "none";
	  } else {
	    x.style.display = "block";
	  }
	}
   	handleImageCropped=(data)=>{

   		console.log('imageData', data);
   		this.setState({openModal: false,isuploading: true,image_preview: ''});
   		//var blob = this.dataURItoBlob(data.croppedImage);
   		var newimage = this.state.image;
   		var blob = this.dataURItoBlob(data.croppedImage);
   		//image_preview=data.croppedImage;
   		//this.setState({image_preview: data.croppedImage })
		// var file = new File([blob], 'test.png', {type: 'image/png', lastModified: Date.now()});
		//console.log('file',blob);
		//alert(data.croppedImage);
		const formData = new FormData(); 

	      // Update the formData object 
      	formData.append("myFile", blob); 
      	formData.append("devicetype", '');
      	//dataURItoBlob(base64), filename
      	const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
      	axios.post(urlConstants.BACKEND_URL+'/wp-json/sections/v1234/uploadChlidImage', formData, config)
      	.then((response) => {
      		var res = JSON.parse(response.data);
        	console.log(res)
        	if(res.message == "success"){
        		this.setState({
		        	isuploading: false,
		        	image_preview: data.croppedImage,
        			isuploadErr: false,
        			imageName:res.file_name
		        })
        	}else{
        		this.setState({
        			isuploading: false,
        			image_preview: false,
        			isuploadErr: true,
        			imageName: res.file_name,
				baseURL: res.base_rul
        		})
        	}
	       
      	}).catch(err=>{
      		this.setState({
    			isuploading: false,
    			image_preview: false,
    			isuploadErr: true,
    			imageName: ''
    		})
      	})

  
   	}

   	handleSubmit(event) {
    	event.preventDefault();
    	
	   		if(!this.state.child_img){
	   			{this.state.selectedLanguage == "english" &&
	   			alert("Please select child avatar");
	   			}
	   			{this.state.selectedLanguage == "French" &&
	   			alert("Veuillez sélectionner l'avatar de l'enfant");
	   			}
	   		}else if(!this.state.name_meaning){
	   			console.log(this.state.mother_img)
	   			{this.state.selectedLanguage == "english" &&
	   			alert("Please add meaning of name");
	   			}
	   			{this.state.selectedLanguage == "French" &&
	   			alert("Veuillez ajouter la signification du nom");
	   			}
	   			
	   		}else if(!this.state.color){
	   			{this.state.selectedLanguage == "english" &&
	   			alert("Please select cover color");
	   			}
	   			{this.state.selectedLanguage == "French" &&
	   			alert("Veuillez sélectionner la couleur de la couverture");
	   			}
	   			
	   		}else if(!this.state.child_type){
	   			{this.state.selectedLanguage == "english" &&
	   			alert("Please select Child type");
	   			}
	   			{this.state.selectedLanguage == "French" &&
	   			alert("Veuillez sélectionner le type d'enfant");
	   			}
	   			
	   		}else{
	   			var data = this.state;
		    	console.log(data);
		    	window.location=urlConstants.BACKEND_URL+'/product/'+this.state.child_type+'-book-'+data.child_avtar_type+'?c_n='+encodeURIComponent(data.child_name)+'&child_type='+this.state.child_type+'&nm='+encodeURIComponent(data.name_meaning)+'&c_i='+data.child_img+'&u_i='+data.imageName+'&c='+data.color+'&g='+data.Glasses+'&av_type='+data.child_avtar_type+'&eid_b=1&char='+this.state.child_avtar_type+'&ap_dedication='+this.state.AttachPhotoEnable+'&lang='+this.state.selectedLanguage;
   		}
   		
   		
  	}
  	componentWillReceiveProps(nextProps){
  		if(nextProps.items){
  			var data = nextProps.items;
  			if(data[0].term_id){
  				var value = JSON.stringify( data[0].description);
  				value = value.replace(/\\n/g, "<br>");
  				value = value.replace(/\\r/g, "");
  				var description = value.substring(1, value.length-1);
  				$(".bookDiscription").html(description);
  				this.setState({bookDiscription: data[0].description});
  			}
  			if(data[0].post_author){
				var reviews = nextProps.items.map((review)=>{
					return  <div className="card-body">
		            	        <div className="row">
		                    	    <div className="col-md-2">
		                    	        <img src="https://image.ibb.co/jw55Ex/def_face.jpg" className="img img-rounded img-fluid"/>
		                    	        <p className="text-secondary text-center"></p>
		                    	    </div>
		                    	    <div className="col-md-10">
		                    	        <p><strong>{review.post_title}</strong>
		                    	        	<span className="float-right"><i className="text-warning fa fa-star"></i></span>
					                        <span className="float-right"><i className="text-warning fa fa-star"></i></span>
					        	            <span className="float-right"><i className="text-warning fa fa-star"></i></span>
					        	            <span className="float-right"><i className="text-warning fa fa-star"></i></span>
					        	            <span className="float-right"><i className="text-warning fa fa-star"></i></span>
					        	            
		                    	        </p>
		                    	        <p>{review.post_content}</p>
		                    	    </div>
		            	        </div>
	            	    </div>
		        });
		        this.setState({reviewsList: reviews});
  			}
  		}
  	}
   	render() {
   		// var popover =;
   		const popover = (
		  <Popover id="popover-basic">
		  <Popover.Title as="h3">Info</Popover.Title>
		  {this.state.selectedLanguage == "english" &&
		    
		    <Popover.Content>
		      Every little Muslims name has a meaning and it is only a google search away!
		    </Popover.Content>
		}
		{this.state.selectedLanguage == "French" &&
		    <Popover.Content>
		      Chaque petit nom musulman a une signification et ce n'est qu'une recherche sur google !
		    </Popover.Content>
		}
		  </Popover>
		);

		const Example = () => (
		  <OverlayTrigger trigger="click" placement="right" overlay={popover}>
		    <Button variant="secondary" className="btn-circle">&#x2148;</Button>
		  </OverlayTrigger>
		);

		const popover2 = (
		  <Popover id="popover-basic">
		    <Popover.Title as="h3">Info</Popover.Title>
		    {this.state.selectedLanguage == "english" &&
		    <Popover.Content>
		      	<p><strong>Photo will appear in the dedication page.</strong></p>
		      	All images and it's contents are treated with highest privacy protection. Refer to privacy policy for further details.
		    </Popover.Content>
			}
			{this.state.selectedLanguage == "French" &&
		    <Popover.Content>
		      	<p><strong>La photo apparaîtra dans la page de dédicace.</strong></p>
		      	Toutes les images et leur contenu sont traités avec la plus haute protection de la vie privée. Consultez la politique de confidentialité pour plus de détails.
		    </Popover.Content>
			}
		  </Popover>
		);

		const Example2 = () => (
		  <OverlayTrigger trigger="click" placement="right" overlay={popover2}>
		    <Button variant="secondary" className="btn-circle">&#x2148;</Button>
		  </OverlayTrigger>
		);
      return (
         <div>
            <Header/>
            	<div className="thumbbook-deatils">
					{/* -Carousel Wrapper--*/}
					<div className="container">
						<div className="row">
							<div className="col-md-7">
								
									<div id="carousel-thumb" className="carousel slide carousel-fade carousel-thumbnails" data-ride="carousel">
										{/*--Slides--*/}
									    <div className="carousel-inner" role="listbox">
									        <div className="carousel-item active">
									            <img className="d-block w-100" src="assets/images/eid-slider-1.png"/>
									        </div>
									        <div className="carousel-item">
									            <img className="d-block w-100" src="assets/images/eid-slider-2.png" alt="Second slide"/>
									        </div>
									        <div className="carousel-item">
									            <img className="d-block w-100" src="assets/images/eid-slider-3.jpeg" alt="Third slide"/>
									        </div>
											 <div className="carousel-item">
									            <img className="d-block w-100" src="assets/images/eid-slider-4.jpeg" alt="Fourth slide"/>
									        </div>
									    </div>
									    {/*--/.Slides--*/}
									    {/*--Controls--*/}
									    <a className="carousel-control-prev" href="#carousel-thumb" role="button" data-slide="prev">
									        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
									        <span className="sr-only">Previous</span>
									       </a>
									    <a className="carousel-control-next" href="#carousel-thumb" role="button" data-slide="next">
									        <span className="carousel-control-next-icon" aria-hidden="true"></span>
									        <span className="sr-only">Next</span>
									    </a>
									    {/*--/.Controls--*/}
									    <ol className="carousel-indicators">
									        <li data-target="#carousel-thumb" data-slide-to="0" className="active"> <img className="d-block w-100" src="assets/images/eid-slider-1.png"/></li>
									        <li data-target="#carousel-thumb" data-slide-to="1"><img className="d-block w-100" src="assets/images/eid-slider-2.png" className="img-fluid"/></li>
									        <li data-target="#carousel-thumb" data-slide-to="2"><img className="d-block w-100" src="assets/images/eid-slider-3.jpeg" className="img-fluid"/></li>
											<li data-target="#carousel-thumb" data-slide-to="3"><img className="d-block w-100" src="assets/images/eid-slider-4.jpeg" className="img-fluid"/></li>
									    </ol> 
									</div>

								{/*this.state.child_type == 'boy' &&
									<div id="carousel-thumb" className="carousel slide carousel-fade carousel-thumbnails" data-ride="carousel">
									
									    <div className="carousel-inner" role="listbox">
									        <div className="carousel-item active">
									            <img className="d-block w-100" src="assets/images/boy-slider-1.jpg" alt="Second slide"/>
									        </div>
									        <div className="carousel-item">
									            <img className="d-block w-100" src="assets/images/boy-slider-2.jpg"/>
									        </div>
									        <div className="carousel-item">
									            <img className="d-block w-100" src="assets/images/boy-slider-3.jpg" alt="Third slide"/>
									        </div>
											 <div className="carousel-item">
									            <img className="d-block w-100" src="assets/images/boy-slider-4.jpg" alt="Fourth slide"/>
									        </div>
									    </div>
									  
									    <a className="carousel-control-prev" href="#carousel-thumb" role="button" data-slide="prev">
									        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
									        <span className="sr-only">Previous</span>
									       </a>
									    <a className="carousel-control-next" href="#carousel-thumb" role="button" data-slide="next">
									        <span className="carousel-control-next-icon" aria-hidden="true"></span>
									        <span className="sr-only">Next</span>
									    </a>
									   
									    <ol className="carousel-indicators">
									        <li data-target="#carousel-thumb" data-slide-to="0" className="active"><img className="d-block w-100" src="assets/images/boy-slider-1.jpg" className="img-fluid"/></li>
									        <li data-target="#carousel-thumb" data-slide-to="1"> <img className="d-block w-100" src="assets/images/boy-slider-2.jpg"/></li>
									        <li data-target="#carousel-thumb" data-slide-to="2"><img className="d-block w-100" src="assets/images/boy-slider-3.jpg" className="img-fluid"/></li>
											<li data-target="#carousel-thumb" data-slide-to="3"><img className="d-block w-100" src="assets/images/boy-slider-4.jpg" className="img-fluid"/></li>
									    </ol> 
									</div>
								*/}
							</div>
							{/*--/.Carousel Wrapper--*/}     
								<div className="col-md-5 right-side-review">
									   	<h2>SubhanAllah, You</h2>
									    <h3><span>Starting from</span> $32.99 USD </h3>
									    {/*<a href="#">Love letter to child</a>*/}
								   	<div className="bottom-reviews">
								    	{/*<a href="#" data-toggle="modal" data-target="#exampleModal"><img src="assets/images/star-ratning.png" alt="stars" />Read our reviews</a>*/}
								     	<p>‘SubhanAllah, You’ is a beautifully written & illustrated love letter to a child that celebrates and captures the wonders of childhood in all it's big and little joys through the eyes of loving parents. A story to appreciate the beauty God has given us. A story a child can grow with. The perfect gift for any occasion at any age (0 to 8).</p>
										<ul>
											<li><span className="badge-bg-style">Birthdays	</span></li>
										     <li><span className="badge-bg-style">Aqiqah</span></li>
										     <li><span className="badge-bg-style">Welcome to the world</span></li>
										     <li><span className="badge-bg-style">Special occasion</span></li>

										</ul>
										{/*<p className="bookDiscription">
											
											} 
											
										</p>*/}
								   </div>
								</div>
						</div>
					</div>			
				</div>
				<div className="modal fade" id="exampleModal"  role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
				  <div className="modal-dialog" role="document">
				    <div className="modal-content">
				      <div className="modal-header">
				        <h5 className="modal-title" id="exampleModalLabel">Customer Reviews</h5>
				        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
				          <span aria-hidden="true">&times;</span>
				        </button>
				      </div>
				      <div className="modal-body">
				      	{this.state.reviewsList &&
						    this.state.reviewsList
						}
				      </div>
				      <div className="modal-footer">
				        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
				      </div>
				    </div>
				  </div>
				</div>
				<div className="form-book">
				  	<form> 
				      	<div className="teal-input-head">
				       		<div className="container">
				       			<div className="row">
				        			<div className="col-lg-10 offset-lg-1">
					      				<h2>Create the child's entire book</h2>
					   	   				<div className="input-first">
					       					<label htmlFor="lname">Language</label>
								           <select defaultValue={this.state.selectedLanguage} className ={this.state.selectedLanguage} id="languagedropdown" onChange={this.languageChange}>
								            
								            <option value="english">English US </option>
								            <option value="French">French </option>
								        	
								        	
								        	
								        	
								        
								           </select>
				           						<span></span>
						  				</div>

					    			</div>
					   			</div>
					  		</div>
					  	</div>
						<div className="container"  >
				  			<div className="row">
				     			<div className="col-lg-10 offset-lg-1 form-started ">
				     			{this.state.selectedLanguage == "english" &&
				     				<form onSubmit={this.handleSubmit}>
					       				<div className="form-side">	
							 				<div className="input-first">
					          					<label>Child Name</label>
					             				<input type="text" id="show_hide" name="child_name" className="flip meaning-of-name" placeholder="Child Name" onChange={this.handleChange} autoComplete="off" value={this.state.child_name} maxLength="18" required/>
							  					{/*<div className="select-lightbox"  id="toggle_tst">
							  						{this.state.child_type == "girl" &&
							  							<React.Fragment>
							  								<label className="container  avtar subhan-girl-saven" >
													           <input type="radio" name="boy" autoComplete="off"  onChange={this.avtarChange} onClick={()=> this.setState({child_img:'girl-av-7.png',child_avtar_type:'G_B_S'})} />
													           <span className="checkmark"></span>
												        	</label>
												        	<label className="container  avtar subhan-girl-saven" style={{'border': 'none','opacity': '0'}}>

												        	</label>
												        	<label className="container  avtar subhan-girl-fifth" >
													           <input type="radio" name="boy" autoComplete="off"  onChange={this.avtarChange} onClick={()=> this.setState({child_img:'girl-av-5.png',child_avtar_type:'G_D_S'})} />
													           <span className="checkmark"></span>
												        	</label>
												        	<label className="container  avtar subhan-girl-sixth" >
													           <input type="radio" name="boy" autoComplete="off"  onChange={this.avtarChange} onClick={()=> this.setState({child_img:'girl-av-6.png',child_avtar_type:'G_D_L'})} />
													           <span className="checkmark"></span>
												        	</label>
															
															<label className="container  avtar subhan-girl-third" >
													           <input type="radio" name="boy" autoComplete="off"  onChange={this.avtarChange} onClick={()=> this.setState({child_img:'girl-av-3.png',child_avtar_type:'G_Bw_S'})} />
													           <span className="checkmark"></span>
												        	</label>												        	
												        	<label className="container  avtar subhan-girl-forth" >
													           <input type="radio" name="boy" autoComplete="off"  onChange={this.avtarChange} onClick={()=> this.setState({child_img:'girl-av-4.png',child_avtar_type:'G_Bw_L'})} />
													           <span className="checkmark"></span>
												        	</label>
												        	

								  							<label className="container avtar subhan-girl-first" >
													           <input type="radio" name="boy"  autoComplete="off"  onChange={this.avtarChange} onClick={()=> this.setState({child_img:'girl-av-1.png',child_avtar_type:'G_L_S'})} />
													           <span className="checkmark"></span>
												        	</label>
												        	<label className="container  avtar subhan-girl-second" > 
													           <input type="radio" name="boy" autoComplete="off"  onChange={this.avtarChange} onClick={()=> this.setState({child_img:'girl-av-2.png',child_avtar_type:'G_L_L'})} />
													           <span className="checkmark"></span>
												        	</label>
												       
												        </React.Fragment>
							  						}
							  						{this.state.child_type == "boy" &&
							  							<React.Fragment>
															<label className="container  avtar subhan-boy-first" >
													           <input type="radio" name="boy" autoComplete="off"  onClick={()=> this.setState({child_img:'9226FB2C-81B1-467B-BB2D-F476FA8F1E54.png',child_avtar_type:'L'})} />
													           <span className="checkmark"></span>
													        </label>
													        <label className="container  avtar subhan-boy-second" >
													           <input type="radio" name="boy" autoComplete="off"  onClick={()=> this.setState({child_img:'FCC15EF3-8A7D-435E-9AC2-F58AF0DB7280.png',child_avtar_type:'D'})} />
													           <span className="checkmark"></span>
													        </label>
													        <label className="container  avtar subhan-boy-third" >
													           <input type="radio" name="boy" autoComplete="off" onClick={()=> this.setState({child_img:'242D0940-CD5C-4440-A03D-3FB301E5EE8A.png',child_avtar_type:'B'})} />
													           <span className="checkmark"></span>
													        </label>
													        <label className="container  avtar subhan-boy-forth" >
													           <input type="radio" name="boy" autoComplete="off" onClick={()=> this.setState({child_img:'242D0940-CD5C-4440-A03D-3FB301E5EE8A.png',child_avtar_type:'B'})} />
													           <span className="checkmark"></span>
													        </label>
													        <label className="container  avtar subhan-boy-fifth" >
													           <input type="radio" name="boy" autoComplete="off" onClick={()=> this.setState({child_img:'242D0940-CD5C-4440-A03D-3FB301E5EE8A.png',child_avtar_type:'B'})} />
													           <span className="checkmark"></span>
													        </label>
													        <label className="container  avtar subhan-boy-sixth" >
													           <input type="radio" name="boy" autoComplete="off" onClick={()=> this.setState({child_img:'242D0940-CD5C-4440-A03D-3FB301E5EE8A.png',child_avtar_type:'B'})} />
													           <span className="checkmark"></span>
													        </label>
													        <label className="container  avtar subhan-boy-saven" >
													           <input type="radio" name="boy" autoComplete="off" onClick={()=> this.setState({child_img:'242D0940-CD5C-4440-A03D-3FB301E5EE8A.png',child_avtar_type:'B'})} />
													           <span className="checkmark"></span>
													        </label>
												        </React.Fragment>
												    }
							   					</div>*/}
							    			</div>
											<div className="input-first" style={{'display': 'inline-flex'}}>
												{/*============= Girls avatars ================*/}
												<div id="mYDIV" className="mydiv">
													<h4>Choose the adventurer in your book</h4>
													<label className="container" onClick={()=> this.setState({child_img:'boy-av-7.png',child_avtar_type:'char1'})}>
											         	<img src="assets/images/boy-av-7.png" alt="boy" />
									                  	<input type="radio" name="GREEN" />
									                  	<span className="checkmark" id="boy_wavy_glasses"></span>
									                </label>

									                <label className="container bothavatars" style={{'border': 'none','background': 'none'}}>
											         	
									                </label>

									                 <label className="container" onClick={()=> this.setState({child_img:'boy-av-5.png',child_avtar_type:'char2'})}>
									         			<img src="assets/images/boy-av-5.png" alt="boy" />
									                  	<input type="radio" name="GREEN" />
									                  	<span className="checkmark" id="boy_curly_glasses"></span>
									                </label>
											        <label className="container" onClick={()=> this.setState({child_img:'boy-av-6.png',child_avtar_type:'char3'})}>
											         	<img src="assets/images/boy-av-6.png" alt="boy" />
									                  	<input type="radio" name="GREEN" />
									                  	<span className="checkmark" id="boy_wavy_glasses"></span>
									                </label>

									                 <label className="container" onClick={()=> this.setState({child_img:'boy-av-3.png',child_avtar_type:'char4'})}>
										        		<img src="assets/images/boy-av-3.png" alt="boy" />
									                  <input type="radio" name="GREEN" />
									                  <span className="checkmark" id="boy_wavy"></span>
									                </label>
											        
											        <label className="container" onClick={()=> this.setState({child_img:'boy-av-4.png',child_avtar_type:'char5'})}>
								         				<img src="assets/images/boy-av-4.png" alt="boy" />
									                  	<input type="radio" name="GREEN" />
									                  	<span className="checkmark" id="boy_straight_glasses"></span>
									                </label>

									                <label className="container" onClick={()=> this.setState({child_img:'boy-av-1.png',child_avtar_type:'char6'})} >
									         			<img src="assets/images/boy-av-1.png" alt="boy" />
									                   	<input type="radio" name="GREEN" />
									                   	<span className="checkmark" id="boy_straight"></span>
									                </label>

											        <label className="container" onClick={()=> this.setState({child_img:'boy-av-2.png',child_avtar_type:'char7'})}>
											         	<img src="assets/images/boy-av-2.png" alt="boy" />
										                <input type="radio" name="GREEN" />
										                <span className="checkmark" id="boy_curly"></span>
											        </label>

											       
											       
											        
											        
											    </div>
										        {/*============= boy avatars ================*/}
										        <div id="myDIV" className="mydiv">
											        <h4>Choose the adventurer in your book</h4>
											        <label className="container" onClick={()=> this.setState({child_img:'girl-av-7.png',child_avtar_type:'char1'})}>
											         	<img src="assets/images/girl-av-7.png" alt="girl" />
									                  	<input type="radio" name="GREEN" />
									                  	<span className="checkmark" id="boy_wavy_glasses"></span>
									                </label>
									                <label className="container bothavatars" style={{'border': 'none','background': 'none'}}>
											         	
									                </label>

									                 <label className="container" onClick={()=>this.setState({child_img:'girl-av-5.png',child_avtar_type:'char2'})}>
									         			<img src="assets/images/girl-av-5.png" alt="girl" />
									                  	<input type="radio" name="GREEN" />
									                  	<span className="checkmark" id="boy_curly_glasses"></span>
									                </label>
											        <label className="container" onClick={()=>this.setState({child_img:'girl-av-6.png',child_avtar_type:'char3'})}>
											         	<img src="assets/images/girl-av-6.png" alt="girl" />
									                  	<input type="radio" name="GREEN" />
									                  	<span className="checkmark" id="boy_wavy_glasses"></span>
									                </label>

									                 <label className="container" onClick={()=>this.setState({child_img:'girl-av-3.png',child_avtar_type:'char4'})}>
										        		<img src="assets/images/girl-av-3.png" alt="girl" />
									                  <input type="radio" name="GREEN" />
									                  <span className="checkmark" id="boy_wavy"></span>
									                </label>
											        
											        <label className="container" onClick={()=>this.setState({child_img:'girl-av-4.png',child_avtar_type:'char5'})}>
								         				<img src="assets/images/girl-av-4.png" alt="girl" />
									                  	<input type="radio" name="GREEN" />
									                  	<span className="checkmark" id="boy_straight_glasses"></span>
									                </label>

													<label className="container" onClick={()=> this.setState({child_img:'girl-av-1.png',child_avtar_type:'char6'})}>
									         			<img src="assets/images/girl-av-1.png" alt="girl" />
									                   	<input type="radio" name="GREEN" />
									                   	<span className="checkmark" id="boy_straight"></span>
									                </label>

											        <label className="container" onClick={()=>this.setState({child_img:'girl-av-2.png',child_avtar_type:'char7'})}>
											         	<img src="assets/images/girl-av-2.png" alt="girl" />
										                <input type="radio" name="GREEN" />
										                <span className="checkmark" id="boy_curly"></span>
											        </label>

											       
											       
											        											        
											    </div>
										        {/*============= boy avatars end ================*/}

												<div className="col-md-6">
								                    <div className="boy-emoji">
								                      <div className="main-gen btn active" id="active" onClick={this.myFunction1}>Boy</div>
								                    </div>
								                  </div>
								                  <div className="col-md-6">
								                    <div className="boy-emoji">
								                       <div className="main-gen btn active" id="active" onClick={this.myFunction}>Girl</div>
								                      </div>
							                    </div>
											</div>
										</div>
					          			<div className="form-side" id="scroll">
								          	<div className="input-first">
								          		<div className="form-check" style={{'padding-left': '0px'}}>
												  <label className="form-check-label">
												  Glasses? 
												  </label>
												 </div>
										    	<div className="form-check">
												  <label className="form-check-label">
												  	 <input type="radio" className="form-check-input" value="1" onChange={this.handleChange} name="Glasses"/>Yes
												  </label>
												</div>
												<div className="form-check">
												  <label className="form-check-label">
												    <input type="radio" className="form-check-input" value="0" onChange={this.handleChange} name="Glasses" checked={this.state.Glasses == '0' ? true : false}/>No
												  </label>
												</div>
										    </div>
										    <div className="input-first"></div>
										</div>
										<div className="form-side" >
								           	<div className="input-first">
								           		<label>Meaning of name  <Example/></label>	
								           		 <textarea id="meaning-of-name-textarea" className="form-control meaning-of-name " rows="5" maxLength="310" id="comment" onKeyDown={this.onKeyPressed} onChange={this.handleChange} placeholder='eg. Ibrahim is the name of a beloved prophet. It means the "Father of Nation". He was named after his loving grandfather.' name="name_meaning"></textarea>
									   			 <p className="limitp"><span id="word_remaining_text">310</span> characters remaining. *Only English language accepted.</p>
									   		</div>
										   	<div className="input-first">
								            </div>
										</div>
							           	<div className="form-side" >
								           	<div className="input-first">
								           		<label>Cover Color</label>		  
											    <button type="button" className={this.state.color == 'navy' ? "btn  btn-circle btn-sm color-active" : "btn  btn-circle btn-sm"}  style={{background: '#304B7A'}} onClick={()=> this.setState({color: 'navy'})}></button> 
											    <button type="button" className={this.state.color == 'ocean' ? "btn  btn-circle btn-sm color-active" : "btn  btn-circle btn-sm"} style={{background: '#6FB7CF'}} onClick={()=> this.setState({color: 'ocean'})}></button> 
											    <button type="button" className={this.state.color == 'sky' ? "btn  btn-circle btn-sm color-active" : "btn  btn-circle btn-sm"} style={{background: '#B1D1E6'}} onClick={()=> this.setState({color: 'sky'})}></button> 
								            	<button type="button" className={this.state.color == 'mint' ? "btn  btn-circle btn-sm color-active" : "btn  btn-circle btn-sm"} style={{background: '#AED3C2'}} onClick={()=> this.setState({color: 'mint'})} ></button> 
											    <button type="button" className={this.state.color == 'lime' ? "btn  btn-circle btn-sm color-active" : "btn  btn-circle btn-sm"} style={{background: '#CCC66E'}} onClick={()=> this.setState({color: 'lime'})}></button>  
											    <button type="button" className={this.state.color == 'lavender' ? "btn  btn-circle btn-sm color-active" : "btn  btn-circle btn-sm"} style={{background: '#B099C3'}} onClick={()=> this.setState({color: 'lavender'})}></button>  
											    <button type="button" className={this.state.color == 'pink' ? "btn  btn-circle btn-sm color-active" : "btn  btn-circle btn-sm"} style={{background: '#D7B5CD'}} onClick={()=> this.setState({color: 'pink'})}></button>  
									   		</div>
										   	<div className="input-first">
								            </div>
										</div>
										<div className="form-side" >
											<div className="input-first attach_photo_option">
								          		<div className="form-check attachoptionlabel" style={{'padding-left': '0px'}}>
												  <label className="form-check-label">
												  Attach photo to dedication? 
												  </label>
												 </div>
										    	<div className="form-check">
												  <label className="form-check-label">
												  	 <input type="radio" className="form-check-input yesoption" value="1" onChange={this.handleChange} name="attach_photo"checked={this.state.AttachPhotoEnable  ? true : false}/>Yes
												  </label>
												</div>
												<div className="form-check">
												  <label className="form-check-label">
												    <input type="radio" className="form-check-input nooption" value="0" onChange={this.handleChange} name="attach_photo" checked={!this.state.AttachPhotoEnable ? true : false}/>No
												  </label>
												</div>
										    </div>
										    {this.state.AttachPhotoEnable &&
											  	<div data-value={this.state.AttachPhotoEnable} className="input-first attach_photo_btn" style={{'text-align': 'left'}}>
											  	
											  		<input type="file" id="add_image" accept="image/*"  onChange={this.handleFileChange} style={{'display':'none'}} />
										           	<label for="add_image" className="add-photo-label" style={{'cursor': 'pointer'}}>Add Photo</label> <Example2/>
										           	<div style={{'display': 'flex','width': '100%','padding': '8px'}}>
										           		{this.state.isuploading && 
										           			<CircularProgress/>

										           		}
										           		{this.state.image_preview &&
										           			<img className="image_preview" src={this.state.image_preview } />
										           		}
										           		{this.state.isuploadErr &&
										           			<Alert severity="error" style={{'width': '100%'}}>
															  <AlertTitle>Error : </AlertTitle>
															  <AlertTitle>{this.state.imageName}</AlertTitle>
															  <AlertTitle>{this.state.baseURL}</AlertTitle>
															  <strong> Failed to upload.</strong>
															</Alert>
										           		}
										           		{this.state.isuploading && 
										           		<p>Loading Photo. Please wait.</p>
										           		}
										           	</div>
											  	</div>
											}
										  	<div className="input-first">
									           
										   	</div>
										</div>
										{this.state.openModal && 
											<DangerButton closeCropper={this.closeCropper} image_uploaded={this.state.image} handleImageCropped={(this.props.handleImageCropped) ? this.props.handleImageCropped : this.handleImageCropped } lang={this.state.selectedLanguage}/>	
										}					  
								        <div className="bottom-submit">  
								          	<input type="submit"  value="View Entire Book" style={{'cursor': 'pointer'}} />
								           	{/*<h5>*For single parent family email us at contactus<a href="#">@wishuponmystory.com</a></h5>*/}
								        </div> 
								    </form>
								}
								{/* ============french form =============*/}
								{this.state.selectedLanguage == "French" &&
								<form onSubmit={this.handleSubmit} >
					       				<div className="form-side">	
							 				<div className="input-first">
					          					<label>Nom de l'enfant</label>
					             				<input type="text" id="show_hide" name="child_name" className="flip meaning-of-name" placeholder="Nom de l'enfant" onChange={this.handleChange} autoComplete="off" value={this.state.child_name} maxLength="18" required/>
							  					{/*<div className="select-lightbox"  id="toggle_tst">
							  						{this.state.child_type == "girl" &&
							  							<React.Fragment>
							  								<label className="container  avtar subhan-girl-saven" >
													           <input type="radio" name="boy" autoComplete="off"  onChange={this.avtarChange} onClick={()=> this.setState({child_img:'girl-av-7.png',child_avtar_type:'G_B_S'})} />
													           <span className="checkmark"></span>
												        	</label>
												        	<label className="container  avtar subhan-girl-saven" style={{'border': 'none','opacity': '0'}}>

												        	</label>
												        	<label className="container  avtar subhan-girl-fifth" >
													           <input type="radio" name="boy" autoComplete="off"  onChange={this.avtarChange} onClick={()=> this.setState({child_img:'girl-av-5.png',child_avtar_type:'G_D_S'})} />
													           <span className="checkmark"></span>
												        	</label>
												        	<label className="container  avtar subhan-girl-sixth" >
													           <input type="radio" name="boy" autoComplete="off"  onChange={this.avtarChange} onClick={()=> this.setState({child_img:'girl-av-6.png',child_avtar_type:'G_D_L'})} />
													           <span className="checkmark"></span>
												        	</label>
															
															<label className="container  avtar subhan-girl-third" >
													           <input type="radio" name="boy" autoComplete="off"  onChange={this.avtarChange} onClick={()=> this.setState({child_img:'girl-av-3.png',child_avtar_type:'G_Bw_S'})} />
													           <span className="checkmark"></span>
												        	</label>												        	
												        	<label className="container  avtar subhan-girl-forth" >
													           <input type="radio" name="boy" autoComplete="off"  onChange={this.avtarChange} onClick={()=> this.setState({child_img:'girl-av-4.png',child_avtar_type:'G_Bw_L'})} />
													           <span className="checkmark"></span>
												        	</label>
												        	

								  							<label className="container avtar subhan-girl-first" >
													           <input type="radio" name="boy"  autoComplete="off"  onChange={this.avtarChange} onClick={()=> this.setState({child_img:'girl-av-1.png',child_avtar_type:'G_L_S'})} />
													           <span className="checkmark"></span>
												        	</label>
												        	<label className="container  avtar subhan-girl-second" > 
													           <input type="radio" name="boy" autoComplete="off"  onChange={this.avtarChange} onClick={()=> this.setState({child_img:'girl-av-2.png',child_avtar_type:'G_L_L'})} />
													           <span className="checkmark"></span>
												        	</label>
												       
												        </React.Fragment>
							  						}
							  						{this.state.child_type == "boy" &&
							  							<React.Fragment>
															<label className="container  avtar subhan-boy-first" >
													           <input type="radio" name="boy" autoComplete="off"  onClick={()=> this.setState({child_img:'9226FB2C-81B1-467B-BB2D-F476FA8F1E54.png',child_avtar_type:'L'})} />
													           <span className="checkmark"></span>
													        </label>
													        <label className="container  avtar subhan-boy-second" >
													           <input type="radio" name="boy" autoComplete="off"  onClick={()=> this.setState({child_img:'FCC15EF3-8A7D-435E-9AC2-F58AF0DB7280.png',child_avtar_type:'D'})} />
													           <span className="checkmark"></span>
													        </label>
													        <label className="container  avtar subhan-boy-third" >
													           <input type="radio" name="boy" autoComplete="off" onClick={()=> this.setState({child_img:'242D0940-CD5C-4440-A03D-3FB301E5EE8A.png',child_avtar_type:'B'})} />
													           <span className="checkmark"></span>
													        </label>
													        <label className="container  avtar subhan-boy-forth" >
													           <input type="radio" name="boy" autoComplete="off" onClick={()=> this.setState({child_img:'242D0940-CD5C-4440-A03D-3FB301E5EE8A.png',child_avtar_type:'B'})} />
													           <span className="checkmark"></span>
													        </label>
													        <label className="container  avtar subhan-boy-fifth" >
													           <input type="radio" name="boy" autoComplete="off" onClick={()=> this.setState({child_img:'242D0940-CD5C-4440-A03D-3FB301E5EE8A.png',child_avtar_type:'B'})} />
													           <span className="checkmark"></span>
													        </label>
													        <label className="container  avtar subhan-boy-sixth" >
													           <input type="radio" name="boy" autoComplete="off" onClick={()=> this.setState({child_img:'242D0940-CD5C-4440-A03D-3FB301E5EE8A.png',child_avtar_type:'B'})} />
													           <span className="checkmark"></span>
													        </label>
													        <label className="container  avtar subhan-boy-saven" >
													           <input type="radio" name="boy" autoComplete="off" onClick={()=> this.setState({child_img:'242D0940-CD5C-4440-A03D-3FB301E5EE8A.png',child_avtar_type:'B'})} />
													           <span className="checkmark"></span>
													        </label>
												        </React.Fragment>
												    }
							   					</div>*/}
							    			</div>
											<div className="input-first" style={{'display': 'inline-flex'}}>
												{/*============= Girls avatars ================*/}
												<div id="mYDIV" className="mydiv">
													<h4>Choose the adventurer in your book</h4>
													<label className="container" onClick={()=> this.setState({child_img:'boy-av-7.png',child_avtar_type:'char1'})}>
											         	<img src="assets/images/boy-av-7.png" alt="boy" />
									                  	<input type="radio" name="GREEN" />
									                  	<span className="checkmark" id="boy_wavy_glasses"></span>
									                </label>

									                <label className="container bothavatars" style={{'border': 'none','background': 'none'}}>
											         	
									                </label>

									                 <label className="container" onClick={()=> this.setState({child_img:'boy-av-5.png',child_avtar_type:'char2'})}>
									         			<img src="assets/images/boy-av-5.png" alt="boy" />
									                  	<input type="radio" name="GREEN" />
									                  	<span className="checkmark" id="boy_curly_glasses"></span>
									                </label>
											        <label className="container" onClick={()=> this.setState({child_img:'boy-av-6.png',child_avtar_type:'char3'})}>
											         	<img src="assets/images/boy-av-6.png" alt="boy" />
									                  	<input type="radio" name="GREEN" />
									                  	<span className="checkmark" id="boy_wavy_glasses"></span>
									                </label>

									                 <label className="container" onClick={()=> this.setState({child_img:'boy-av-3.png',child_avtar_type:'char4'})}>
										        		<img src="assets/images/boy-av-3.png" alt="boy" />
									                  <input type="radio" name="GREEN" />
									                  <span className="checkmark" id="boy_wavy"></span>
									                </label>
											        
											        <label className="container" onClick={()=> this.setState({child_img:'boy-av-4.png',child_avtar_type:'char5'})}>
								         				<img src="assets/images/boy-av-4.png" alt="boy" />
									                  	<input type="radio" name="GREEN" />
									                  	<span className="checkmark" id="boy_straight_glasses"></span>
									                </label>

									                <label className="container" onClick={()=> this.setState({child_img:'boy-av-1.png',child_avtar_type:'char6'})} >
									         			<img src="assets/images/boy-av-1.png" alt="boy" />
									                   	<input type="radio" name="GREEN" />
									                   	<span className="checkmark" id="boy_straight"></span>
									                </label>

											        <label className="container" onClick={()=> this.setState({child_img:'boy-av-2.png',child_avtar_type:'char7'})}>
											         	<img src="assets/images/boy-av-2.png" alt="boy" />
										                <input type="radio" name="GREEN" />
										                <span className="checkmark" id="boy_curly"></span>
											        </label>

											       
											       
											        
											        
											    </div>
										        {/*============= boy avatars ================*/}
										        <div id="myDIV" className="mydiv">
											        <h4>Choose the adventurer in your book</h4>
											        <label className="container" onClick={()=> this.setState({child_img:'girl-av-7.png',child_avtar_type:'char1'})}>
											         	<img src="assets/images/girl-av-7.png" alt="girl" />
									                  	<input type="radio" name="GREEN" />
									                  	<span className="checkmark" id="boy_wavy_glasses"></span>
									                </label>
									                <label className="container bothavatars" style={{'border': 'none','background': 'none'}}>
											         	
									                </label>

									                 <label className="container" onClick={()=>this.setState({child_img:'girl-av-5.png',child_avtar_type:'char2'})}>
									         			<img src="assets/images/girl-av-5.png" alt="girl" />
									                  	<input type="radio" name="GREEN" />
									                  	<span className="checkmark" id="boy_curly_glasses"></span>
									                </label>
											        <label className="container" onClick={()=>this.setState({child_img:'girl-av-6.png',child_avtar_type:'char3'})}>
											         	<img src="assets/images/girl-av-6.png" alt="girl" />
									                  	<input type="radio" name="GREEN" />
									                  	<span className="checkmark" id="boy_wavy_glasses"></span>
									                </label>

									                 <label className="container" onClick={()=>this.setState({child_img:'girl-av-3.png',child_avtar_type:'char4'})}>
										        		<img src="assets/images/girl-av-3.png" alt="girl" />
									                  <input type="radio" name="GREEN" />
									                  <span className="checkmark" id="boy_wavy"></span>
									                </label>
											        
											        <label className="container" onClick={()=>this.setState({child_img:'girl-av-4.png',child_avtar_type:'char5'})}>
								         				<img src="assets/images/girl-av-4.png" alt="girl" />
									                  	<input type="radio" name="GREEN" />
									                  	<span className="checkmark" id="boy_straight_glasses"></span>
									                </label>

													<label className="container" onClick={()=> this.setState({child_img:'girl-av-1.png',child_avtar_type:'char6'})}>
									         			<img src="assets/images/girl-av-1.png" alt="girl" />
									                   	<input type="radio" name="GREEN" />
									                   	<span className="checkmark" id="boy_straight"></span>
									                </label>

											        <label className="container" onClick={()=>this.setState({child_img:'girl-av-2.png',child_avtar_type:'char7'})}>
											         	<img src="assets/images/girl-av-2.png" alt="girl" />
										                <input type="radio" name="GREEN" />
										                <span className="checkmark" id="boy_curly"></span>
											        </label>

											       
											       
											        											        
											    </div>
										        {/*============= boy avatars end ================*/}

												<div className="col-md-6">
								                    <div className="boy-emoji">
								                      <div className="main-gen btn active" id="active" onClick={this.myFunction1}>Garçon</div>
								                    </div>
								                  </div>
								                  <div className="col-md-6">
								                    <div className="boy-emoji">
								                       <div className="main-gen btn active" id="active" onClick={this.myFunction}>Fille</div>
								                      </div>
							                    </div>
											</div>
										</div>
					          			<div className="form-side" id="scroll">
								          	<div className="input-first">
								          		<div className="form-check" style={{'padding-left': '0px'}}>
												  <label className="form-check-label">
												 Lunettes?
												  </label>
												 </div>
										    	<div className="form-check">
												  <label className="form-check-label">
												  	 <input type="radio" className="form-check-input" value="1" onChange={this.handleChange} name="Glasses"/>Oui
												  </label>
												</div>
												<div className="form-check">
												  <label className="form-check-label">
												    <input type="radio" className="form-check-input" value="0" onChange={this.handleChange} name="Glasses" checked={this.state.Glasses == '0' ? true : false}/>ne pas
												  </label>
												</div>
										    </div>
										    <div className="input-first"></div>
										</div>
										<div className="form-side" >
								           	<div className="input-first">
								           		<label>Signification du nom  <Example/></label>	
								           		 <textarea id="meaning-of-name-textarea" className="form-control meaning-of-name " rows="5" maxLength="310" id="comment" onKeyDown={this.onKeyPressed} onChange={this.handleChange} placeholder="par exemple. Ibrahim est le nom d'un prophète bien-aimé. Cela signifie le 'Père de la Nation'. Il a été nommé d'après son grand-père aimant." name="name_meaning"></textarea>
									   			 <p className="limitp"><span id="word_remaining_text">310</span> caractères restants. *Seule la langue anglaise est acceptée.</p>
									   		</div>
										   	<div className="input-first">
								            </div>
										</div>
							           	<div className="form-side" >
								           	<div className="input-first">
								           		<label>Couleur de la couverture</label>		  
											    <button type="button" className={this.state.color == 'navy' ? "btn  btn-circle btn-sm color-active" : "btn  btn-circle btn-sm"}  style={{background: '#304B7A'}} onClick={()=> this.setState({color: 'navy'})}></button> 
											    <button type="button" className={this.state.color == 'ocean' ? "btn  btn-circle btn-sm color-active" : "btn  btn-circle btn-sm"} style={{background: '#6FB7CF'}} onClick={()=> this.setState({color: 'ocean'})}></button> 
											    <button type="button" className={this.state.color == 'sky' ? "btn  btn-circle btn-sm color-active" : "btn  btn-circle btn-sm"} style={{background: '#B1D1E6'}} onClick={()=> this.setState({color: 'sky'})}></button> 
								            	<button type="button" className={this.state.color == 'mint' ? "btn  btn-circle btn-sm color-active" : "btn  btn-circle btn-sm"} style={{background: '#AED3C2'}} onClick={()=> this.setState({color: 'mint'})} ></button> 
											    <button type="button" className={this.state.color == 'lime' ? "btn  btn-circle btn-sm color-active" : "btn  btn-circle btn-sm"} style={{background: '#CCC66E'}} onClick={()=> this.setState({color: 'lime'})}></button>  
											    <button type="button" className={this.state.color == 'lavender' ? "btn  btn-circle btn-sm color-active" : "btn  btn-circle btn-sm"} style={{background: '#B099C3'}} onClick={()=> this.setState({color: 'lavender'})}></button>  
											    <button type="button" className={this.state.color == 'pink' ? "btn  btn-circle btn-sm color-active" : "btn  btn-circle btn-sm"} style={{background: '#D7B5CD'}} onClick={()=> this.setState({color: 'pink'})}></button>  
									   		</div>
										   	<div className="input-first">
								            </div>
										</div>
										<div className="form-side" >
											<div className="input-first attach_photo_option">
								          		<div className="form-check attachoptionlabel" style={{'padding-left': '0px'}}>
												  <label className="form-check-label">
												  Joindre une photo à la dédicace ?
												  </label>
												 </div>
										    	<div className="form-check">
												  <label className="form-check-label">
												  	 <input type="radio" className="form-check-input yesoption" value="1" onChange={this.handleChange} name="attach_photo"checked={this.state.AttachPhotoEnable  ? true : false}/>Oui
												  </label>
												</div>
												<div className="form-check">
												  <label className="form-check-label">
												    <input type="radio" className="form-check-input nooption" value="0" onChange={this.handleChange} name="attach_photo" checked={!this.state.AttachPhotoEnable ? true : false}/>ne pas
												  </label>
												</div>
										    </div>
										    {this.state.AttachPhotoEnable &&
											  	<div data-value={this.state.AttachPhotoEnable} className="input-first attach_photo_btn" style={{'text-align': 'left'}}>
											  	
											  		<input type="file" id="add_image" accept="image/*"  onChange={this.handleFileChange} style={{'display':'none'}} />
										           	<label for="add_image" className="add-photo-label" style={{'cursor': 'pointer'}}>Ajouter une photo</label> <Example2/>
										           	<div style={{'display': 'flex','width': '100%','padding': '8px'}}>
										           		{this.state.isuploading && 
										           			<CircularProgress/>

										           		}
										           		{this.state.image_preview &&
										           			<img className="image_preview" src={this.state.image_preview } />
										           		}
										           		{this.state.isuploadErr &&
										           			<Alert severity="error" style={{'width': '100%'}}>
															  <AlertTitle>Error</AlertTitle>
															  <strong> Failed to upload.</strong>
															</Alert>
										           		}
										           		{this.state.isuploading && 
										           		<p>Loading Photo. Please wait.</p>
										           		}
										           	</div>
											  	</div>
											}
										  	<div className="input-first">
									           
										   	</div>
										</div>
										{this.state.openModal && 
											<DangerButton closeCropper={this.closeCropper} image_uploaded={this.state.image} handleImageCropped={(this.props.handleImageCropped) ? this.props.handleImageCropped : this.handleImageCropped } lang={this.state.selectedLanguage}/>	
										}					  
								        <div className="bottom-submit">  
								          	<input type="submit"  value="Voir le livre entier" style={{'cursor': 'pointer'}} />
								           	{/*<h5>*Pour les familles monoparentales, écrivez-nous à contactus<a href="#">@wishuponmystory.com</a></h5>*/}
								        </div> 
								    </form>
								}
				          		</div>
				          	</div>
				        </div>
				    </form> 
				</div>
            <Footer/>
         </div>
      );
   }
}
const mapStateToProps = (state) => {
    return {
	    items: state.items,
	};
};
const actionCreators = {
  bookContant: Action.bookContant,
  parentsPost: Action.parentsPost,
  getCategoryDetails: Action.getCategoryDetails,
}

export default connect(mapStateToProps, actionCreators)(detailsEidBook);