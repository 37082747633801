import React from 'react';
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import {Action} from '../_action/action';
import $ from "jquery";
import { urlConstants } from '../_constant/url_constant';
import axios from 'axios';
import { history } from '../_helpers/history';

class App extends React.Component {
   constructor(props) {
       super(props);
       this.state = {
        paretsDesignation: '',
        subscriber_email: ''
       }
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleChange = this.handleChange.bind(this);
   }

   componentDidMount() {
      this.props.parentsPost();
      this.slider();
      this.props.GetConstant();
   }
   slider(){
     window.$('.bxslider').bxSlider({
          autoHover: true,
          auto: true,
          slideWidth: 400,
          minSlides: 2,
          maxSlides: 2,
          controls: true,
          pager: true,
          speed: 400,
          responsive: true,
          captions: true,
          slideMargin: 90,
        });
        window.$('.bxslider1').bxSlider({
          auto: true,
          stopAutoOnClick: true,
          passive: true,
          touchEnabled: false,
          prevText: true,
          nextText: true,
          slideWidth: 1900
        });

        window.$('a.smooth').click(function(){
            $('html, body').animate({
                scrollTop: $( $.attr(this, 'href') ).offset().top
            }, 1400);
            return false;
        });
   }
   handleChange(e){
    if(e){
      console.log(e.target.value);
      this.setState({subscriber_email: e.target.value});
    }
   }
   handleSubmit(event){
    event.preventDefault();
      axios.get(urlConstants.BACKEND_URL+'/wp-json/sections/v1234/add_subscriber?mail='+this.state.subscriber_email)
      .then((response) => {
        if(response.data == 'success'){
          $(".subscriber p.sucess").show();
          this.setState({subscriber_email : ''})
        }else{
          $(".aleradyexits").show()
        }
      })
   }
   componentWillReceiveProps(nextProps) {
    if(nextProps.items){
      var data = nextProps.items;

      //return html of parents desgination
      var posts = data.map((post)=>{
          return  <div className="slider-box">
              <p>{post.post_content}</p>
              <div className="bottm-conslider-box"> 
                  <h5>{post.post_title}</h5>
              </div>
          </div>
        });

      //set time for bxslider js callt
      this.setState({parentsDesignation: posts})
      setTimeout(() => {
          this.slider();
        }, 500);
      }
  }
   render() {
      return (
         <div>
            <Header/>
                <div>
                  <section className="site-banner"> 
                   <div className="bxslider1">
                     <div className="box">
                       <img src="assets/images/banner.jpg" alt="Los Angeles" style={{width:'100%'}}/>
                          <div className="banner-content">
                          
                            </div>
                     </div>
                     <div className="box">
                       <img src="assets/images/slide-2.png" alt="Chicago" style={{width:'100%'}}/>
                          <div className="banner-content">
                          
                            </div>
                     </div>
                   </div>
				   <div class="banner-desc desktopview">
  <h1>Beautiful stories that make lifetime memories.</h1>
  <p>Personalized books for little muslims.</p>
  <div class="banner-action"><a href="/our-books"> Shop now </a> </div>
</div>
<div class="banner-desc mobileresponsive"><div class="banner-action"><a href="/our-books"> Shop now </a></div> </div>
                   </section> 
                   {/*-- banner section end--*/}
                  
                   {/*-- stroy books section start --*/}
                   <section className="stroy-books">
                       <div className="container">
                           <div className="row align-items-center">
                               <div className="col-sm-8">
                                   <div className="cont-box">
                                       <p>Personalized story books, <span style={{'color':'#6B7986'}}>for little Muslims.</span></p>
                                   </div>
                               </div>
                               <div className="col-md-4">
                                   <div className="read-btn">
                                       <a  className="smooth" href="#your-book">Shop Now</a>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </section>
                   {/*-- stroy books section end --*/}
                   {/*-- steps section start --*/}
                   <section className="steps">
                       <div className="container">
                           <h3 className="title">Personalize your Story-Book</h3>
                           <h4 className="sub-title">in just a few easy steps!</h4>
                           <div className="row">
                               <div className="col-md-4 col-sm-6">
                                   <div className="book-selves">
                                       <span className="counts">1</span>
                                       <img src="assets/images/child.png" alt="child"/>
                                       <h3>Personalize one child</h3>
                                       <p>Choose name and avatar for your superstar and fill in further details</p>
                                   </div>
                               </div>
                               {/*<div className="col-md-3 col-sm-6">
                                   <div className="book-selves">
                                       <span className="counts">2</span>
                                       <img src="assets/images/parents-friend.png" alt="parents-friend"/>
                                       <h3>Personalize parents and a friend</h3>
                                       <p>Customize what child calls their loved ones</p>
                                   </div>
                               </div>*/}
                               <div className="col-md-4 col-sm-6">
                                   <div className="book-selves preview-book">
                                       <span className="counts">2</span>
                                       <img src="assets/images/preview-book.png" alt="preview-book"/>
                                       <h3>Preview your Book</h3>
                                       <p>See a sample perview</p>
                                   </div>
                               </div>
                               <div className="col-md-4 col-sm-6">
                                   <div className="book-selves">
                                       <span className="counts">3</span>
                                       <img src="assets/images/print-ship.png" alt="print-ship"/>
                                       <h3>Print and Ship</h3>
                                      
                                   </div>
                               </div>
                           </div>
                       </div>
                   </section>
                   {/*-- steps section end --*/}
                   {/*-- your book section start --*/}
                   <section className="your-book" id="your-book">
                       <div className="container">
                           <img src="assets/images/book-icon.png" alt="book-icon"/>
                           <h3 className="title">My Perfect Eid Book</h3>
                           <div className="row">
                               <div className="col-md-5 offset-xl-1  offset-md-1">
                                   <div className="build-book girl">
                                       <img src="assets/images/girl-book.png" alt="girl"/>
                                       <Link to="/book-details?child=girl">Build a girl book</Link>
                                   </div>
                               </div>
                               <div className="col-md-5">
                                   <div className="build-book boy">
                                       <img src="assets/images/boy-book.png" alt="boy"/>
                                       <Link to="/book-details?child=boy">Build a boy book</Link>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </section>
                   {/*-- your book section end --*/}
                    {/*-- your book comming soon section start --*/}
                   <section className="your-book" id="your-book">
                       <div className="container">
                           <h3 className="title">SUBHAN ALLAH, You</h3>
                           <div className="row">
                              {/* <div className="col-md-5 offset-xl-1  offset-md-1">
                                   <div className="build-book subhan-allah-girl">
                                       <img src="assets/images/subhan_allah_girl_book.png" alt="girl"/>
                                       <Link to="/details-eid-book?child=girl">Build a girl book</Link>
                                   </div>
                               </div>*/}
                               <div className="col-md-8" style={{'margin':'auto'}}>
                                   <div className="build-book subhan-allah-boy">
                                       <img src="assets/images/add-eid-book.png" alt="boy"/>
                                       <Link to="/Details">Build a book</Link>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </section>
                   {/*-- your comming soon book section end --*/}
                   {/*-- three box section start --*/}
                   <section className="three-box">
                       <div className="container">
                           <div className="row">
                               <div className="col-md-4">
                                   <div className="content-layout">
                                       <div className="img-box">
                                          <a href="https://g.page/r/CTacijodARcnEAg/review" target="_blank"><img src="assets/images/star.png" alt="star"/></a>
                                       </div>
                                       <p>Read our reviews</p>
                                   </div>
                               </div>
                               <div className="col-md-4">
                                   <div className="content-layout">
                                       <div className="img-box">
                                          <Link to="/media"><img src="assets/images/camera-icon.png" alt="Include"/></Link> 
                                       </div>
                                       <p>Media</p>
                                   </div>
                               </div>
                               <div className="col-md-4">
                                   <div className="content-layout">
                                       <div className="img-box">
                                          <a href="#subscriber">   <img src="assets/images/subscirbe-icon-new.png" alt="slod"/> </a>
                                       </div>
                                       <p>Subscribe and save 10%</p>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </section>
                   {/*-- three box section end --*/}
                   {/*-- muslims section start --*/}
                   <section className="muslims">
                       <div className="container">
                           <div className="row">
                               <div className="col-md-7">
                                   <div className="muslims-img">
                                       <img src="assets/images/muslims.png" alt="muslims"/>
                                   </div>
                               </div>
                               <div className="col-md-5">
                                   <div className="muslims-content">
                                       <h3>Books that promote confidence and self identity in little Muslims</h3>
                                       <p>Here at Wish upon my story, bringing diversity to our personalized book is a priority so that your child can identify themselves (literally!) in the book. Our books are all about Islamic and cultural traditions which makes them special for the whole family! Our books make the perfect gift for your loved ones on Eid, aqiqah, birthdays or any special occasions. We are committed to making every mini Muslim the star of their own storybook and give them an experience to treasure for a lifetime insha Allah.</p>
                                       <Link to="/about-us" className="btn-books">Read Our Story <i className="fa fa-long-arrow-right"></i></Link>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </section>
                  <div className="slider-testimonial">
                     <div className="container">
                        <div className="row">
                          {this.state.parentsDesignation &&
                           <div className="col-xl-10 offset-xl-1">
                              <h3 className="title"> What our Happy Parents say </h3>
                              <div className="bxslider logosImgs">
                                  {this.state.parentsDesignation}
                              </div>
                           </div>
                          }
                        </div>
                     </div>
                  </div>
                  <section className="subscriber" id="subscriber">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-8">
                          <h5>SUBSCRIBE TO HEAR ABOUT NEW ARRIVALS AND OFFERS!</h5>
                          <p>Join our email list and receive exclusive savings and new product updates. Unsubscribe anytime using the link in our email footer. Read our privacy policy. </p>
                        </div>
                        <div className="col-md-4">
                          <form onSubmit={this.handleSubmit}>
                            <div className="input_mail">
                              <input type="text" name="email" placeholder="Enter email" onChange={this.handleChange} value={this.state.subscriber_email} required/>
                            </div>
                            <div className="subscribe_submit">
                              <input type="submit" value="JOIN US"/>
                            </div>
                          </form>
                          <p className='sucess'>Your subscription has been confirmed.</p>
                          <p className='failed'>Failed.</p>
                          <p className='aleradyexits'>You are alerady our subscriber.</p>
                        </div>
                      </div>
                    </div>
                  </section>
               </div>
            <Footer/>
         </div>
      );
   }
}

const mapStateToProps = (state) => {
   return {
     items: state.items,
     constant: state.constant
   };
};
const actionCreators = {
  parentsPost: Action.parentsPost,
  GetConstant: Action.GetConstant,
}

export default connect(mapStateToProps, actionCreators)(App);