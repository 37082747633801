import React from 'react';
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import { Link } from 'react-router-dom';
import cookie from 'react-cookies';
import $ from "jquery";
import { connect } from 'react-redux';
import {Action} from '../_action/action';
console.log(Action)


class BookPreview extends React.Component {
	constructor(props) {
       super(props);
       this.state = {
       		total_item:1,
       		product_id:115,
       		formate: 'Softcover',
       		dedication: ' Dear abcd,You have the potential to be anything in the universe you like.Believe in yourself and nothing can stop you.',
       		child_img:'',
	       	mother_img:'',
	       	father_img:'',
	       	friend_img:'',
	       	child_name: '',
	       	mother_name: '',
	       	father_name: '',
	       	friend_name: ''
       }
       this.openCity = this.openCity.bind(this);
       this.handleSubmit = this.handleSubmit.bind(this);
       this.creatProduct = this.creatProduct.bind(this);
       this.addToCart = this.addToCart.bind(this);
   	}

   	componentDidMount() {
   		this.props.parentsPost();
   		var th = this;
		window.$(document).ready(function(){
		  window.$("#hide").click(function(){
		  	th.setState({formate: 'Hardcover '});
		    window.$(".tabbing-elements").hide();
		  });
		  window.$("#show").click(function(){

		    window.$(".tabbing-elements").show();
		  });
		});
		window.$(document).ready(function(){
		  window.$("#hide1").click(function(){
		  	th.setState({formate: 'Softcover '});
		    window.$(".tabbing-elements").hide();
		  });
		  window.$("#show1").click(function(){
		    window.$(".tabbing-elements").show();
		  });
		});
		window.$('a.smooth').click(function(){
		    window.$('html, body').animate({
		        scrollTop: window.$( window.$.attr(this, 'href') ).offset().top
		    }, 1400);
		    return false;
		});
		window.$("#flipbook").turn({
			autoCenter: true,
			center: true,
		});
   	}
 	openCity(evt, cityName) {
		  var i, tabcontent, tablinks;
		  tabcontent = document.getElementsByClassName("tabcontent");
		  for (i = 0; i < tabcontent.length; i++) {
		    tabcontent[i].style.display = "none";
		  }
		  tablinks = document.getElementsByClassName("tablinks");
		  for (i = 0; i < tablinks.length; i++) {
		    tablinks[i].className = tablinks[i].className.replace(" active", "");
		  }
		  document.getElementById(cityName).style.display = "block";
		  evt.currentTarget.className += " active";
	}
	handleSubmit = (event) =>{
		event.preventDefault();
		this.setState({dedication: $("#dedication").val()});
		window.$(".tabbing-elements").hide();
	}
	creatProduct(){
		console.log("creating");
		this.props.createProduct();
	}
	addToCart(){
		var data = this.state;
		this.props.addToCart(data);
	}
	componentWillReceiveProps(nextProps){
		console.log(nextProps)
		if(nextProps.book_contant){
			this.setState({
				child_img:nextProps.book_contant.child_img,
		       	mother_img:nextProps.book_contant.mother_img,
		       	father_img:nextProps.book_contant.father_img,
		       	friend_img:nextProps.book_contant.friend_img,
		       	child_name: nextProps.book_contant.child_name,
		       	mother_name: nextProps.book_contant.mother_name,
		       	father_name: nextProps.book_contant.father_name,
		       	friend_name: nextProps.book_contant.friend_name
			})
		}	
	}

   	render() {
      return (
         <div>
            <Header/>
            	{/*-- top tabbing --*/}
				 	<div className="toptab-preview">
				  		<div className="container">
				  			<h2>Sample Preview</h2>
						   	<p>*Note that your book will be printed with your avatar selection</p>
							<ul className="nav nav-tabs" role="tablist">
							    <li className="nav-item">
							      <a className="nav-link active" data-toggle="tab" href="#home"><img src="assets/images/child-1.png" alt="img-1"/></a>
							    </li>
							    <li className="nav-item">
							      <a className="nav-link" data-toggle="tab" href="#menu1"><img src="assets/images/child-2.png" alt="img-1"/></a>
							    </li>
							    <li className="nav-item">
							      <a className="nav-link" data-toggle="tab" href="#menu2"><img src="assets/images/child-3.png" alt="img-1"/></a>
							    </li>
								<li className="nav-item">
							      <a className="nav-link" data-toggle="tab" href="#menu3"><img src="assets/images/child-4.png" alt="img-1"/></a>
							    </li>
						  	</ul>
				  			{/*-- tab content --*/}
							<div className="tab-content">
							    <div id="home" className="container tab-pane active"><br/>
							    	<div className="row">
							    	 	<div id="flipbook">
										    <div className="hard" >Book Preview<br/><br/>
										    	Child Name: {this.state.child_name}<br/>
										    	She calls her mother: {this.state.mother_name}<br/>
										    	She calls her father: {this.state.father_name}<br/>
										    	A name of child’s friend: {this.state.friend_name}<br/>
										    </div>
										    <div className="hard" >
										    	{this.state.dedication} 
										    </div>
										    <div style={{backgroundImage:'url("assets/images/preview-left.png")'}}> </div>
										    <div style={{backgroundImage:'url("assets/images/preview-right.png")'}}>  </div>
										    <div style={{backgroundImage:'url("assets/images/preview-left.png")'}}></div>
										    <div style={{backgroundImage:'url("assets/images/preview-right.png")'}}></div>
										    <div className="hard"></div>
										    <div className="hard"></div>
										</div>

								      	{/*<div className="col-md-6 img-left">
								       		<img src="assets/images/preview-left.png" alt="left"/>
								       	</div>
							       		<div className="col-md-6 img-right">
								  			<div className="view">
								   				<div className="view-back">
								   					<img src="assets/images/preview-right.png"/>
												</div>
												<div className="slice s1" style={{backgroundImage: 'url(./assets/images/preview-right.png)'}}>
													<span className="overlay"></span>
													<div className="slice s2" style={{backgroundImage:' url(./assets/images/preview-right.png)'}}>
														<span className="overlay"></span>
														<div className="slice s3" style={{backgroundImage: 'url(./assets/images/preview-right.png)'}}>
															<span className="overlay"></span>
															<div className="slice s4" style={{backgroundImage: 'url(./assets/images/preview-right.png)'}}>
																<span className="overlay"></span>
																<div className="slice s5" style={{backgroundImage: 'url(./assets/images/preview-right.png)'}}>
																	<span className="overlay"></span></div>
																</div>
															</div>
														</div>
													</div>
												</div>
							      			</div>*/}
				     					</div>
				    				</div>
				   					<div id="menu1" className="container tab-pane fade"><br/>
				    					<div className="row">
				     						<div className="col-md-6 img-right">
				       							<img src="assets/images/preview-left.png" alt="left"/>
				       						</div>
				       						<div className="col-md-6 img-left">
				       							<img src="assets/images/preview-right.png" alt="right"/>
				      						</div>
				     					</div>
				    				</div>
				    				<div id="menu2" className="container tab-pane fade"><br/>
				    					<div className="row">
				      						<div className="col-md-6 img-left">
				       							<img src="assets/images/preview-left.png" alt="left"/>
				       						</div>
				       						<div className="col-md-6 img-right">
				       							<img src="assets/images/preview-right.png" alt="right"/>
				      						</div>
				     					</div>
				    				</div>
					  				<div id="menu3" className="container tab-pane fade"><br/>
					    				<div className="row">
					      					<div className="col-md-6 img-right">
					       						<img src="assets/images/preview-left.png" alt="left"/>
					       					</div>
					      					<div className="col-md-6 img-right">
					       					<img src="assets/images/preview-left.png" alt="right"/>
					      				</div>
				     				</div>
				    			</div>
				  			</div>
							{/*-- tab content --*/}
				    	</div>
					</div>
				{/*-- top tabbing end --*/}
				{/*--  add you book section --*/}
  				<div className="main-bann-book">
			      	<div className="tabbing-elements" id="main-box">
				      	<div className="container">
				  	    	<div id="London" className="tabcontent">
				         		<div className="main-title">
			          				<h2>Choose a format for your book</h2>
			           				<p>Select your option below</p>
			          			</div>
					   			<div className="row">
					      			<div className="col-lg-6">
					       				<div className="left-book-options">
						     				<div className="top-left-title">
						     					<p>For lifetime keepsake</p>
						      					<h3>Hardcover </h3>
							 				</div>
							 				<div className="inner-rightt">
						     			<div className="left-image"> 
						     				<h5>$34.99 <span> USD</span> </h5> 
						     				<img src="assets/images/where.png" alt="book"/>
						     			</div>
						      			<p>Choose our hardcover edition (8.5 x 11 inches) - with personalized cover - for a polished and longlasting keepsake. </p>
						   			</div>
						     		<a href="#main-box" id="hide" className="select smooth"> <span > Select Hardcover </span></a>
					       		</div>
					      	</div>
						    <div className="col-lg-6">
						      	<div className="right-book-options">
					           		<h4>Our original softcover</h4>
					           		<h2>Softcover</h2>
							   		<div className="inner-rightt">
						       			<div className="left-image"> <h5>$34.99 <span> USD</span> </h5> 
						       				<img src="assets/images/where.png" alt="book"/>
						       			</div>
							    			<p> Our original softcover size (8.5 x 11 inches) - with personalized cover - that is coated and water resistant.</p>
							   		</div>
							    		<a href="#main-box"  id="hide1" className="select smooth"><span> Select Softcover</span></a>    
					          		</div>	    
					         	</div>	    
					        </div>	    
					    </div> 
			          	<div id="Paris" className="tabcontent">
			           		<div className="top-title">
				            	<p>Write your own dedication and sign-off for Neha&nbsp;‑ FREE</p>
				            	<h3>We’ll print this on the first page, exactly as you've written it</h3>
				            </div>
				            <div className="row">
						       	<div className="col-md-6"> 
						        	<div className="left-second_tab"> 
							     		<img src="assets/images/wish-story.jpg" alt="pro-img"/>
							    	</div>
							   	</div>
						   		<div className="col-md-6"> 
						     		<div className="right-second_tab"> 
							     	<form  onSubmit={this.handleSubmit}>
						          		<textarea id="dedication">
										   Dear abcd,
					                        You have the potential to be anything in the universe you like.Believe in yourself and nothing can stop you.
									  	</textarea>
							      		<p>278 characters remaining </p> <button type="submit"> save </button>
									</form>
						    	</div>
							  	</div>
							</div>
				        </div>
				    </div>
				</div>
				  	<form>
					    <div className="container">
					    	<div className="row tab">
					       		<div className="col-md-4  select-book  tablinks" id="show" onClick={(event)=>this.openCity(event, 'London')}>
						    		<div className="con-vel">
						     			<p>Book Format
					          			</p>
						      			<label></label>
						      		</div>
					            	<input type="checkbox" checked="checked"/>
							    	<label><img src="assets/images/checkmarka.png" alt="checkmark"/>
					            	<p>{this.state.formate}</p>
					            	</label>
					          	</div>
					         	<div  id="show1"  className="col-md-4 select-book tablinks" onClick={(event)=>this.openCity(event,'Paris')}>
					         		<div className="con-vel">
							      		<textarea>hello...</textarea>
							       		<label></label>
						      		</div>
					            	<label>
								     	<img src="assets/images/checkmarka.png" alt="checkmark"/>
							            <p>Dedication</p>
									</label>
					        	</div>
					       		<div className="col-md-4 select-add">
						    		<a onClick={this.addToCart}> Add to cart</a> 
						   		</div>
					     	</div>
					    </div>
				   </form>
			  	</div>
            <Footer/>
         </div>
      );
   }
}

const mapStateToProps = (state) => {
   return {
     isLoading: state.isLoading,
     response: state.response,
     book_contant: state.book_contant,
     items: state.items,
   };
};
const actionCreators = {
   	parentsPost: Action.parentsPost,
  	createProduct: Action.createProduct,
  	addToCart: Action.addToCart
}

export default connect(mapStateToProps, actionCreators)(BookPreview);