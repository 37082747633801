import React from 'react';
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import { Link } from 'react-router-dom';
import cookie from 'react-cookies';
import $ from "jquery";

class AboutUs extends React.Component {

   	componentDidMount() {
   		window.$('html, body' ).scrollTop( 0 );
   	}
   render() {
      return (
         <div>
            <Header/>
            	{/*--  cart-center --*/}
			    <div className="add-book-cart">
				    <div className="container">
				      	<h2>Personalized books help to:</h2>
					    <div className="row">
						  	<div className="col-md-12">
						  		<ol className="li-style">
						  			<li>Boost confidence</li>
									<li>Keep children engaged in reading more</li>
									<li>Strengthen self-identity</li>
						  		</ol>
						  		
						  	</div>
						  	<div className="col-md-12">
						  		<div style={{width:'22%',float:'left'}}>
						  			<img src="assets/images/aboutUs-book-image2.png"/>
						  			
						  		</div>
						  		<div style={{width:'22%',float:'left',marginLeft: '4%'}}>
						  			<img src="assets/images/aboutUs-book-image.png"/>
						  		</div>
						  	</div>	
						  	<div className="col-md-12">

						  		<p>

						  			Wish upon my story is a personalized children book brand for little Muslims that instill self-confidence and strengthen self-identity as a Muslim. This product is for parents of young Muslims that are looking for a lifelong keepsake/gift for little ones to encourage them to learn about their Islamic traditions in a personal, memorable and fun way!<br/><br/>

									Meet Tasmea and Shakib, the co-founders of Wish upon my story. They are parents like you, raising two little Muslims in the western society and they know how important it is for their children to grow up embracing their Muslim identity. They know all too well the struggles of finding ways to keep children interested and engaged in learning their own traditions. Cue light bulb moment! They took the concept of personalized books and tailored it for Muslim children. Tasmea has a Masters degree in Science from University of Toronto and is a former employee of a Pharma company who always had a passion for creativity and illustration. Shakib is Computer Engineering graduate working in software development who was the tech expert behind the personalization. Together with the help of talented and qualified editors, developers and artists/creatives, they have built the very FIRST personalized Eid book!<br/><br/>

									Personalization helps children relate to the storylines – because after all, the story is about them! This makes them far more receptive to the key messages and learnings of each tale – learning about their Islamic culture, self-identity and creating lasting memories with their families and loved ones. Our books embody and support diversity and inclusion.<br/><br/>
						  		</p>
						    </div>	
						</div>
					</div>
				</div>
				{/*--  cart-center  end--*/}
            <Footer/>
         </div>
      );
   }
}

export default AboutUs;