import React from 'react';
import { Link } from 'react-router-dom';
import $ from "jquery";
import {urlConstants} from '../../_constant/url_constant';
import { connect } from 'react-redux';
import {Action} from '../../_action/action';
import { browserName, browserVersion } from "react-device-detect";

class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
        	headerText: '',
       	}
	}
	componentDidMount() {
		if(browserName == 'Edge' || browserName == 'IE'){
			$("#BroserModal").addClass("show");
			$("#BroserModal").css({
				'display': 'block',
	    		'background': 'rgba(0, 0, 0, 0.8)',
	    		'opacity': '1'
			});
		}
		$("#btnClose").click(function () {
			$("#BroserModal").hide();
		})
		var currentPage = window.location.href.split('/')[3];
		if(currentPage == 'about-us'){
			$('#aboutUs').addClass("btn-books");
		}else if(currentPage == 'our-books'){
			$('#ourBook').addClass("btn-books");
		}
		else if(currentPage == 'FAQ'){
			$('#faq').addClass("btn-books");
		}
		this.props.GetConstant();
	}
	componentWillReceiveProps(nextProps) {
      var headerText = nextProps.constant;
      if(headerText){
        // this.setState({headerText: headerText.HeaderText})
        $("#headerText").html(headerText.HeaderText);
      }
    }
   render() {
      return (
         <header className="site-header">
	        <div className="top-bar">
	        <div className="col-sm-12">
	        	 <div className="modal fade" id="BroserModal" role="dialog">
				    <div className="modal-dialog">
				      <div className="modal-content">
				        <div className="modal-body">
				          <p>Your broswer dose not support our website content. </p>
				          <a href="chrome://www.wishuponmystory.com">Open in Chrome.</a>
				        </div>
				        <div className="modal-footer">
				          <button type="button" className="btn btn-default" id="btnClose" data-dismiss="modal">Close</button>
				        </div>
				      </div>
				      
				    </div>
				</div>
                <div className="btn-shopping" id="headerText">
                   
                </div>
                <div className="right-header">
                    <p className="pull-right">Ship to US, CAN, UK, AUS, GERMANY, FRANCE</p>
                </div>
            </div>
	            <div className="container">
	                <div className="row align-items-center">
	                    
	                    {/*<div className="col-sm-4">
	                        <div className="featured">
	                            <Link to="#" class="faq-con"> Faq </Link>
	                        </div>
	                    </div>*/}
	                </div>
	            </div>
	        </div>
	        <div className="header-menu">
	            <div className="container">
	                <div className="row align-items-center">
	                    <div className="col-sm-3">
	                        <div className="site-logo">
	                            <Link to="/"><img src="assets/images/logo.png" alt="logo"/></Link>
	                        </div>
	                    </div>
	                    <div className="col-sm-9">
	                        <div className="menu-box">
	                            <ul>
	                            	<li><Link to="/our-books" id='ourBook' className="">Our books</Link></li>
	                                <li><Link to="/about-us" id='aboutUs' className=''>About Us</Link></li>
	                                <li><Link to="/FAQ" id='faq' className="">FAQ</Link></li>
	                                <li>
	                                	<a href={urlConstants.BACKEND_URL+'cart/'} id='faq' className="">
	                                		<i className="fa">&#xf07a;</i>
	                                	</a>
	                                </li>
	                            </ul>
	                        </div>
	                    </div>
	                </div>
	            </div>
	        </div>
	    </header>
      )
   }
}
const mapStateToProps = (state) => {
   return {
     constant: state.constant
   };
};
const actionCreators = {
  GetConstant: Action.GetConstant,
}

export default connect(mapStateToProps, actionCreators)(Header);