import React from 'react';
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import cookie from 'react-cookies';
import { Link } from 'react-router-dom';
import $ from "jquery";
import { history } from '../_helpers/history';
class Login extends React.Component {
	constructor(props) {
       super(props);
        this.state = {
       		password : ''
       }
       this.handleSubmit = this.handleSubmit.bind(this);
       this.handleChange = this.handleChange.bind(this);

   	}
   	 	componentDidMount() {
	      if(cookie.load('userId')){
	      	 window.location = "/";
	      }
	  }


   	handleChange(e){
   		console.log(e.target.value);
   		let str =e.target.value;
      console.log(str.toLowerCase());
      this.setState({password: str.toLowerCase()})
   	}
   	handleSubmit(event){
   		event.preventDefault();
   		if(this.state.password == 'memory'){
   			cookie.save('userId', this.state.password)
   			  window.location="/";
        
   		}else{
   			alert("password not matched!");
   		}
   		
   	}

   render() {
     // if(cookie.load('userId')){
     //       window.location = "/";
     //  }
      return (
         <div>
          
        	{/*--  cart-center --*/}
		    <div className="add-book-cart">
			    <div className="container">
			      	<h2>Enter The Password To Pre-order.</h2>
				    <form onSubmit={this.handleSubmit}>
			        	<div className="input-first">
			        		<input type="password" id="password" name="password" onChange={this.handleChange} className="enter-password" placeholder="* * * * * * * *" autocomplete="off" maxlength="15" required=""/>
			        	</div>
			        	<div>
			        		<input type="submit" className="submit-password" value="Enter"/>
			        	</div>
			      	</form>
				</div>
			</div>
			{/*--  cart-center  end--*/}
           
         </div>
      );
   }
}

export default Login;