import React from 'react';
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import cookie from 'react-cookies';
import { Link } from 'react-router-dom';
import $ from "jquery";

class FAQ extends React.Component {
	componentDidMount() {
	     
	}
   render() {
      return (
         <div>
            <Header/>
            	{/*--  cart-center --*/}
			    <div className="privacy-content">
				    <div className="container">
					      	<h2> FAQ </h2>
						    <div className="row">
							  	<div className="col-md-12">
							    	<h3>What if I made a mistake when placing my order?</h3>

									<p>Congratulations on creating your personalized book Alhamdulillah. It is being specially made for you.</p>

									<p>Once the order is placed, it will be sent to printer any time within the next 12 hours. If you think you have made a mistake, please contact us within 12 hours of the order. Though we try our best to accommodate any correction and request, there is no guarantee that we can correct the mistake in time before it goes to the printer. We emphasize that you preview the order details, your shipping address and go through the sample preview book in order to catch any mistakes before you confirm your orders.</p>

									<h3>How do I track my orders?</h3>

									<p><a name="_GoBack"></a>Once the book is sent to the printer, it will take 3-5 business days to print and then ship. Please allow 2-3 weeks after that time for the book to arrive at your doorstep. We will be sending you an email when the book gets sent to our printer. After this time, if you wish to know the progress of the book, you can email us at <a href="mailto:contactus@wishuponmystory.com">contactus@wishuponmystory.com</a> with your order ID and we will respond to you within 24 hours.</p>

									<p>Once the book has been shipped, its traceability will depend on the type of shipping method you have chosen. The regular mailing option is NOT trackable we are afraid. Priority mailing option will be trackable in most cases.</p>

									<h3>How long will it take to deliver my order?</h3>

									<p>From the date you place the order, it may take anytime between 2-4 weeks. At the time of the checkout, the estimated delivery date will be provided to you inshaAllah.</p>

									<h3>Can I cancel my order?</h3>

									<p>These personalized books are made just for you. We know how important it is for our customers to receive the books on time. We typically do not delay sending the books to the printer and will do so within 12 hours of the order. Therefore our window to cancel the order is quite short. We will try to accommodate your request as best as we can inshaAllah. Your understanding is appreciated, if for any reason we cannot.</p>

									<h3>Before you finalize your order...</h3>




									<ol className="li-style">
										<li>
											<p>Check the sample book preview to have an overview of the book and its content.</p>
											<p>(**note the sample book preview is a sample only so the correct choice of avatar will not be reflected in the flipbook preview. The text names of the avatar will be reflected. Thank you for understanding.)</p>

										</li>
										<li>
										<p>Check for misspellings</p>
										</li>
										<li>
										<p>Verify the name(s) are correct and any date(s)</p>
										</li>
										<li>
										<p>If you've added a photo, make sure the quality is good (not too dark, clear)</p>
										</li>
										<li>
										<p>Make sure your shipping address is correct</p>
										</li>
									</ol>

									<h3>Do you make books for children with single parent?</h3>

									<p>It is important for us to be inclusive to all Muslim children and their diverse families. Single parent option is definitely something we are looking into. Please do email us at <a href="mailto:contactus@wishuponmystory.com">contactus@wishuponmystory.com</a> to let us know if you are interested in the single parent version.</p>

									<h3>Can I include the child&rsquo;s sibling in the book?</h3>

									<p>The books are meant to be for a single child and the books are based on their gender as well. For the current books available, we do not have an option to add a sibling avatar. However, the child&rsquo;s name character limit in our form is 15 characters. If you wish to, you can include two sibling&rsquo;s name in the text as long as it fits within the character limit and given that both siblings are the same gender (ie. two brothers or two sisters).</p>

									<h3>Do you create books with faceless option?</h3>

									<p>At this time, we do not create books with faceless option.</p>
									
								</div>
							</div>
					</div>
				</div>
				{/*--  cart-center  end--*/}
            <Footer/>
         </div>
      );
   }
}

export default FAQ;